import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../hooks/AuthContext';

const useSubmitAssessment = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const { authData } = useAuth();
  const access_token = authData?.access_token || authData?.accessToken;

  const submitAssessment = async (assessmentData) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const requestBody = {
        assessmentId: assessmentData.assessmentId,
        receipient: assessmentData.recipient || 'SELF', // Use the recipient from assessmentData
        name: assessmentData.name,
        compliance: assessmentData.compliance,
        response: assessmentData.questions.map(question => ({
          question: question.question,
          considerScore: question.considerScore,
          options: question.options.map(option => ({
            text: option.text,
            score: option.score || 0,
            weight: option.weight || 1,
            level: option.level || null // Include the level property
          }))
        })),
        toolsUsed: assessmentData.tools || [],
        is_packaged: assessmentData.is_packaged || false,
        is_draft: assessmentData.is_draft || false,
        package_id: assessmentData.package_id || null,
        package_eval_id: assessmentData.package_eval_id || null
      };

      const response = await axios.post(
        "https://app.spiralreports.com/api/evaluations/new", 
        requestBody,
        {
          headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSuccess(true);
      setLoading(false);
      return response.data;
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Error submitting the assessment.';
      setError(Array.isArray(errorMessage) ? errorMessage.join(', ') : errorMessage);
      setLoading(false);
      throw err;
    }
  };

  return {
    submitAssessment,
    loading,
    error,
    success,
  };
};

export default useSubmitAssessment;