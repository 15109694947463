import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import ReferralModal from '../modal/ReferralModal';
import logo1 from '../../assets/SpiralReports Logo White.jpg';
import logo2 from '../../assets/logoBlack.png';
import {
  Home,
  Search,
  List,
  FileText,
  CreditCard,
  Settings,
  Users,
  ChevronUp,
  User,
  LogOut,
  ChevronDown,
  Plus,
} from 'lucide-react';

const UnifiedSearchPage = () => {
  const navigate = useNavigate();
  const { authData, logout } = useAuth();
  const [isReferralModalOpen, setIsReferralModalOpen] = React.useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [scrolled, setScrolled] = React.useState(false);

  // Navigation items
  const navItems = [
    { icon: Home, label: 'Dashboard', badge: null, path: '/dashboard' },
    { icon: Search, label: 'Search Assessments', badge: null, path: '/search' },
    { icon: List, label: 'Completed Assessments', badge: null, path: '/assessmentsPage' },
    { icon: FileText, label: 'Draft Assessments', badge: '3', path: '/drafts' },
    { icon: CreditCard, label: 'Credit History', badge: null, path: '/credits' },
    { icon: User, label: 'Settings', badge: null, path: '/settings' },
  ];

  // Handle scroll
  React.useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Fetch user profile
  React.useEffect(() => {
    const fetchUserProfile = async () => {
      const accessToken = authData?.access_token || authData?.accessToken;

      if (!accessToken) {
        setError('Authentication required');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://app.spiralreports.com/api/users/profile', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile data');
        }

        const data = await response.json();
        setUserProfile(data);
        setLoading(false);
      } catch (err) {
        console.error('Profile fetch error:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    if (authData) {
      fetchUserProfile();
    } else {
      setLoading(false);
    }
  }, [authData]);

  // Process profile data
  const profileData = React.useMemo(() => {
    if (userProfile?.data) {
      return {
        name: userProfile.data.firstName && userProfile.data.lastName
          ? `${userProfile.data.firstName} ${userProfile.data.lastName}`.trim()
          : userProfile.data.name || 'Guest',
        email: userProfile.data.email || 'guest@example.com',
        credits: Number(userProfile.data.credits || 0),
        profileImage: userProfile.data.profileImage || '/api/placeholder/80/80',
      };
    }

    if (authData) {
      return {
        name: authData.firstName && authData.lastName
          ? `${authData.firstName} ${authData.lastName}`.trim()
          : authData.name || 'Guest',
        email: authData.email || 'guest@example.com',
        credits: Number(authData.credits || 0),
        profileImage: authData.profileImage || '/api/placeholder/80/80',
      };
    }

    return {
      name: 'Guest',
      email: 'guest@example.com',
      credits: 0,
      profileImage: '/api/placeholder/80/80',
    };
  }, [userProfile, authData]);

  const handleNavigate = React.useCallback((path) => {
    navigate(path);
  }, [navigate]);

  const handleLogout = React.useCallback(() => {
    logout();
    navigate('/login');
  }, [logout, navigate]);

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Sidebar */}
      <div className="w-72 h-screen fixed left-0 top-0 bg-white border-r border-gray-100 flex flex-col">
        {/* Logo Section */}
        <div className="px-6 h-16 flex items-center border-b border-gray-100">
          <img
            src={logo1}
            alt="Logo"
            style={{
              height: scrolled ? '30px' : '55px',
              width: 'auto',
              transition: 'height 0.3s ease',
              cursor: 'pointer',
            }}
            onClick={handleLogoClick}
          />
        </div>

        {/* Credits Section */}
        <div className="px-4 pt-6 pb-4">
          <div className="bg-gray-50 rounded-xl p-4">
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center gap-2">
                <CreditCard size={20} className="text-gray-400" />
                <span className="text-sm font-medium text-gray-600">Available Credits</span>
              </div>
              <button
                onClick={() => handleNavigate('/add-credits')}
                className="p-1.5 rounded-lg hover:bg-white text-gray-400 hover:text-red-500 transition-colors"
              >
                <Plus size={18} />
              </button>
            </div>
            <div className="text-2xl font-semibold text-gray-900">
              {profileData.credits.toLocaleString()}
            </div>
          </div>
        </div>

        {/* Navigation */}
        <nav className="flex-1 px-3">
          <div className="space-y-0.5">
            {navItems.map((item, index) => {
              const Icon = item.icon;
              const isActive = window.location.pathname === item.path;

              return (
                <button
                  key={index}
                  onClick={() => handleNavigate(item.path)}
                  className="w-full relative flex items-center px-3 py-2.5 rounded-lg transition-all duration-200 group"
                >
                  {isActive && (
                    <div className="absolute left-0 top-1/2 -translate-y-1/2 w-1 h-6 bg-red-500 rounded-r-full" />
                  )}
                  <div
                    className={`flex items-center w-full ${
                      isActive ? 'text-red-500' : 'text-gray-600 hover:text-gray-900'
                    }`}
                  >
                    <div
                      className={`flex items-center justify-center w-9 h-9 rounded-lg ${
                        isActive ? 'bg-red-50' : 'group-hover:bg-gray-50'
                      }`}
                    >
                      <Icon
                        size={19}
                        className={isActive ? 'text-red-500' : 'text-gray-400 group-hover:text-gray-600'}
                      />
                    </div>
                    <span className={`ml-3 text-sm ${isActive ? 'font-semibold' : 'font-medium'}`}>
                      {item.label}
                    </span>
                    {item.badge && (
                      <span className="ml-auto bg-red-500 text-white px-2 py-0.5 rounded-full text-xs font-medium">
                        {item.badge}
                      </span>
                    )}
                  </div>
                </button>
              );
            })}
          </div>
        </nav>

        <div className="mt-auto px-3">
          {/* Referral Section */}
          <div className="px-3 mb-3">
            <div className="bg-red-50 rounded-xl p-4 border border-red-100">
              <div className="flex items-center gap-3 mb-3">
                <div className="p-2 bg-white rounded-lg shadow-sm">
                  <Users size={18} className="text-red-500" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 text-sm">Refer Friends</h3>
                  <p className="text-xs text-gray-500">Get 50 free credits per referral</p>
                </div>
              </div>
              <button
                onClick={() => setIsReferralModalOpen(true)}
                className="w-full flex items-center justify-center px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-all duration-200 text-sm font-medium"
              >
                Invite Friends
              </button>
            </div>
          </div>

          {/* Profile Section */}
          <div className="border-t border-gray-100 px-3 py-3">
            <div className="relative">
              <button
                onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                className="w-full flex items-center gap-3 px-3 py-2 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <img
                  src={profileData.profileImage}
                  alt="Profile"
                  className="w-8 h-8 rounded-full object-cover border border-gray-200"
                />
                <div className="flex-1 text-left">
                  <div className="text-sm font-medium text-gray-900">{profileData.name}</div>
                  <div className="text-xs text-gray-500">{profileData.email}</div>
                </div>
                {isProfileMenuOpen ? (
                  <ChevronUp size={16} className="text-gray-400" />
                ) : (
                  <ChevronDown size={16} className="text-gray-400" />
                )}
              </button>

              {/* Profile Dropdown */}
              {isProfileMenuOpen && (
                <div className="absolute bottom-full left-0 w-full mb-2 bg-white border border-gray-100 rounded-lg shadow-lg py-1">
                  <button
                    onClick={() => handleNavigate('/profile')}
                    className="w-full flex items-center gap-2 px-4 py-2.5 text-sm text-gray-600 hover:bg-gray-50"
                  >
                    <User size={16} />
                    Profile Settings
                  </button>
                  <button
                    onClick={() => handleNavigate('/settings')}
                    className="w-full flex items-center gap-2 px-4 py-2.5 text-sm text-gray-600 hover:bg-gray-50"
                  >
                    <Settings size={16} />
                    Account Settings
                  </button>
                  <button
                    onClick={handleLogout}
                    className="w-full flex items-center gap-2 px-4 py-2.5 text-sm text-red-500 hover:bg-gray-50"
                  >
                    <LogOut size={16} />
                    Sign Out
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <main className="flex-1 overflow-y-auto p-6 ml-72">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-600">Loading...</p>
          </div>
        ) : (
          <>
            <h1 className="text-2xl font-semibold text-gray-800">
              Welcome, {profileData.name}
            </h1>
            <p className="text-gray-600 mt-2">This is the main content area.</p>
          </>
        )}

        <ReferralModal
          isOpen={isReferralModalOpen}
          onClose={() => setIsReferralModalOpen(false)}
        />
      </main>
    </div>
  );
};

export default UnifiedSearchPage;