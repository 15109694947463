import React, { useState } from 'react';
import { Search, ArrowRight, Users, CheckCircle, Clock, UserPlus } from 'lucide-react';

const GroupAssessmentCard = ({ group, onSelect, isSelected }) => (
  <div 
    className={`bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-200 relative
      ${isSelected ? 'ring-2 ring-red-500 transform scale-[1.02]' : 'hover:scale-[1.01]'}`}
  >
    <div 
      className={`absolute top-4 right-4 cursor-pointer z-10 transition-transform duration-200
        ${isSelected ? 'transform scale-110' : 'hover:scale-110'}`}
      onClick={() => onSelect(group.id)}
    >
      <CheckCircle 
        className={`h-6 w-6 ${isSelected ? 'text-red-500' : 'text-gray-300'}`} 
        fill={isSelected ? 'currentColor' : 'none'}
      />
    </div>
    
    <div className="p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-red-50 rounded-xl">
            <Users className="h-5 w-5 text-red-500" />
          </div>
          <h3 className="text-lg font-semibold text-gray-800 line-clamp-1">{group.name}</h3>
        </div>
      </div>
      
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-6 text-sm text-gray-600">
          <div className="flex items-center gap-1">
            <UserPlus className="h-4 w-4" />
            <span>{group.memberCount}</span>
          </div>
          <div className="flex items-center gap-1">
            <Clock className="h-4 w-4" />
            <span>{group.activeMembers}</span>
          </div>
          <div className="flex flex-wrap gap-2">
            {group.tags.slice(0, 2).map((tag, index) => (
              <span 
                key={index} 
                className="px-3 py-1 bg-gray-50 text-gray-600 rounded-full text-sm font-medium"
              >
                {tag}
              </span>
            ))}
            {group.tags.length > 2 && (
              <span className="text-sm text-gray-500">+{group.tags.length - 2}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const GroupAssessmentPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups] = useState([
    {
      id: 1,
      name: "Leadership Development Team",
      memberCount: 15,
      activeMembers: 12,
      tags: ["Leadership", "Management", "Team Building"],
      progress: 75,
    },
    {
      id: 2,
      name: "Sales Excellence Group",
      memberCount: 25,
      activeMembers: 20,
      tags: ["Sales", "Customer Relations", "Communication"],
      progress: 60,
    },
    {
      id: 3,
      name: "Technical Skills Workshop",
      memberCount: 30,
      activeMembers: 28,
      tags: ["Technical", "Programming", "Development"],
      progress: 85,
    },
    {
      id: 4,
      name: "Customer Service Training",
      memberCount: 20,
      activeMembers: 18,
      tags: ["Customer Service", "Communication", "Problem Solving"],
      progress: 90,
    },
    {
      id: 5,
      name: "Project Management Circle",
      memberCount: 12,
      activeMembers: 10,
      tags: ["Project Management", "Agile", "Leadership"],
      progress: 45,
    },
    {
      id: 6,
      name: "Digital Marketing Team",
      memberCount: 18,
      activeMembers: 15,
      tags: ["Marketing", "Social Media", "Analytics"],
      progress: 70,
    }
  ]);

  const handleSelectGroup = (groupId) => {
    setSelectedGroups(prev => 
      prev.includes(groupId) 
        ? prev.filter(id => id !== groupId)
        : [...prev, groupId]
    );
  };

  const handleStartAssessment = () => {
    console.log('Starting assessment for groups:', selectedGroups);
  };

  const filteredGroups = groups.filter((group) => {
    const lowercasedSearchQuery = searchQuery.toLowerCase();
    return (
      group.name.toLowerCase().includes(lowercasedSearchQuery) ||
      group.tags.some((tag) => tag.toLowerCase().includes(lowercasedSearchQuery))
    );
  });

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-gradient-to-r from-red-500 to-red-600 pt-20 pb-4">
        <div className="max-w-7xl mx-auto px-8 flex justify-between items-center">
          <div className="text-white">
            <Users className="h-6 w-6" />
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-r from-red-500 to-red-600 pt-8 pb-16">
        <div className="max-w-7xl mx-auto px-8">
          <h1 className="text-3xl font-bold text-white">Group Assessments</h1>
          <p className="text-red-100 mt-2">Select the groups you want to assess</p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-8 -mt-8 pb-52">
        <div className="relative mb-12">
          <div className="absolute inset-y-0 left-0 pl-5 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search Assessments..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-12 pr-6 py-4 rounded-xl border border-gray-200 shadow-sm 
              focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent
              transition-shadow duration-200"
          />
        </div>

        <div className="max-w-7xl mx-auto px-8 mt-6">
          <div className="mb-12">
            <div className="flex justify-between items-center mb-8">
              {/* <div>
                <h2 className="text-2xl font-bold text-gray-800">Active Groups</h2>
                <p className="text-gray-500 mt-1">
                  {selectedGroups.length > 0 
                    ? `${selectedGroups.length} groups selected`
                    : 'No groups selected'}
                </p>
              </div> */}
              {selectedGroups.length > 0 ? (
                <button 
                  onClick={handleStartAssessment}
                  className="px-6 py-3 bg-red-500 text-white font-medium rounded-xl 
                    hover:bg-red-600 transform hover:scale-105 transition-all duration-200
                    shadow-sm hover:shadow-md"
                >
                  Start Assessment ({selectedGroups.length})
                </button>
              ) : (
               <></>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredGroups.length > 0 ? (
                filteredGroups.map((group) => (
                  <GroupAssessmentCard
                    key={group.id}
                    group={group}
                    onSelect={handleSelectGroup}
                    isSelected={selectedGroups.includes(group.id)}
                  />
                ))
              ) : (
                <div className="col-span-full text-center py-12 text-gray-500">
                  No groups found matching your search.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupAssessmentPage;