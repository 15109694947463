import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import Sidebar from '../components/sidebar/sidebar';
import Header from '../components/Header'; // Import the Header component

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="flex flex-col h-screen bg-gray-50">
      {/* Header (fixed at the top) */}
  

      {/* Main Content (with Sidebar and children) */}
      <div className="flex flex-1 mt-16">
        {/* Sidebar (below the header) */}
        <div className="w-72 bg-white border-r border-gray-100">
          <Sidebar onNavigate={handleNavigate} onLogout={handleLogout} />
        </div>

        {/* Main Content Area */}
        <div className="flex-1 overflow-y-auto p-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;