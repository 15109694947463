import React, { useState, useEffect, useRef } from 'react';
import { Search, MoreVertical, Edit2, ArrowLeft, Trash2, Clock, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useAuth } from '../hooks/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DraftAssessmentsPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFramework, setSelectedFramework] = useState('all');
    const [isMoreMenuOpen, setIsMoreMenuOpen] = useState({});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [draftToDelete, setDraftToDelete] = useState(null);
    const [drafts, setDrafts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [continuationLoading, setContinuationLoading] = useState(false);
    const [continuationError, setContinuationError] = useState('');
    const menuRefs = useRef({});
    const modalRef = useRef(null);
    const navigate = useNavigate();
    const { authData } = useAuth();

    const access_token = authData?.access_token || authData?.accessToken;

    useEffect(() => {
        fetchDrafts();
    }, [currentPage, access_token]);

    const fetchDrafts = async () => {
        try {
            setIsLoading(true);
            const filterParams = { is_draft: "true" };
            const encodedFilter = encodeURIComponent(JSON.stringify(filterParams));
            
            const response = await fetch(
                `https://app.spiralreports.com/api/evaluations?page=${currentPage}&limit=10&orderBy=asc&filter=${encodedFilter}`,
                {
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch drafts');
            }

            const result = await response.json();
            setDrafts(result.data.data || []);
            setTotalPages(result.data.totalPages || 1);
            setError(null);
        } catch (err) {
            setError('Failed to load draft assessments');
            console.error('Error fetching drafts:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleContinueAssessment = async (assessmentId, evaluationId) => {
        setContinuationLoading(true);
        setContinuationError('');
        
        try {
            // Fetch assessment details
            const assessmentResponse = await fetch(
                `https://app.spiralreports.com/api/assessments/${assessmentId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (!assessmentResponse.ok) {
                throw new Error('Failed to fetch assessment details');
            }
    
            const assessmentData = await assessmentResponse.json();
            
            if (!assessmentData?.data) {
                throw new Error('Invalid assessment data received');
            }
    
            // Fetch evaluation details
            const evaluationResponse = await fetch(
                `https://app.spiralreports.com/api/evaluations/${evaluationId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (!evaluationResponse.ok) {
                throw new Error('Failed to fetch evaluation details');
            }
    
            const evaluationData = await evaluationResponse.json();
            const evaluation = evaluationData?.data?.data?.[0] || evaluationData?.data || null;
            
            if (!evaluation) {
                throw new Error('No evaluation data found');
            }

            // Close the more menu
            setIsMoreMenuOpen({});

            // Navigate to assessment quiz
            navigate('/assessmentsquiz', {
                state: {
                    assessment: assessmentData.data,
                    existingEvaluation: evaluation,
                    accessToken: access_token
                }
            });
    
        } catch (err) {
            console.error('Error:', err);
            setContinuationError(err.message || 'Failed to load assessment. Please try again.');
            toast.error(err.message || 'Failed to load assessment. Please try again.');
        } finally {
            setContinuationLoading(false);
        }
    };

    const handleGroupAssessmentContinuation = async (draft) => {
        setContinuationLoading(true);
        setContinuationError('');
    
        try {
            // Fetch package evaluation data first
            const packageResponse = await fetch(
                `https://app.spiralreports.com/api/package-eval/${draft.package_eval_id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            if (!packageResponse.ok) {
                throw new Error('Failed to fetch package evaluation');
            }
            
            const packageData = await packageResponse.json();
            const packageInfo = packageData.data[0];
    
            if (!packageInfo) {
                throw new Error('No package data found');
            }
    
            // Get all assessments in order
            const assessmentPromises = packageInfo.assessments.map(async (assessment) => {
                const assessmentResponse = await fetch(
                    `https://app.spiralreports.com/api/assessments/${assessment._id}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${access_token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
    
                if (!assessmentResponse.ok) {
                    throw new Error(`Failed to fetch assessment ${assessment._id}`);
                }
    
                const result = await assessmentResponse.json();
    
                if (!result.data || !result.data.questionSet) {
                    throw new Error(`Invalid assessment data for ${assessment._id}`);
                }
    
                return result.data;
            });
    
            const assessmentResults = await Promise.all(assessmentPromises);
    
            // Find current assessment index
            const currentAssessmentId = packageInfo.evaluations.find(
                evaluation => evaluation._id === draft._id
            )?.assessmentId;
    
            const currentAssessmentIndex = assessmentResults.findIndex(
                assessment => assessment._id === currentAssessmentId
            );
    
            if (currentAssessmentIndex === -1) {
                throw new Error('Could not find current assessment in package');
            }
    
            // Fetch current evaluation data
            const evaluationResponse = await fetch(
                `https://app.spiralreports.com/api/evaluations/${draft._id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (!evaluationResponse.ok) {
                throw new Error('Failed to fetch evaluation data');
            }
    
            const evaluationData = await evaluationResponse.json();
            const evaluation = evaluationData?.data?.data?.[0] || evaluationData?.data;
    
            if (!evaluation) {
                throw new Error('No evaluation data found');
            }
    
           // Get all remaining assessments including the current one
           const remainingAssessments = assessmentResults.slice(currentAssessmentIndex);
            
           // Map evaluations to assessment IDs for quick lookup
           const evaluationMap = new Set(packageInfo.evaluations.map(evaluation => evaluation.assessmentId));
           
           // Add 'new' flag to assessments that don't have evaluations
           const flaggedAssessments = remainingAssessments.map(assessment => ({
               ...assessment,
               new: !evaluationMap.has(assessment._id)
           }));

           // Close the more menu
           setIsMoreMenuOpen({});

           // Navigate to assessment quiz with complete data
           navigate('/assessmentsquiz', {
               state: {
                   assessment: {
                       ...flaggedAssessments[0], // Current assessment with new flag
                       new: !evaluationMap.has(flaggedAssessments[0]._id)
                   },
                   packageEvalId: draft.package_eval_id,
                   packageData: {
                       ...packageInfo,
                       assessments: assessmentResults, // Include all fetched assessments
                   },
                   existingEvaluation: evaluation,
                   remainingAssessments: flaggedAssessments.slice(1), // Rest of the assessments with new flags
                   isPackaged: true,
                   accessToken: access_token
               }
           });
    
        } catch (err) {
            console.error('Error handling group assessment:', err);
            setContinuationError('Failed to load group assessment. Please try again.');
            toast.error('Failed to load group assessment. Please try again.');
        } finally {
            setContinuationLoading(false);
        }
    };
    
    // Update calculateCompletion function to handle package assessments
    const calculateCompletion = (draft) => {
        if (!draft) return 0;
    
        // For group assessments, check package evaluation progress
        if (draft.is_packaged && draft.package_eval_id) {
            const evaluatedAssessments = draft.subdomainScore?.length || 0;
            const totalAssessments = draft.assessments?.length || 1;
            return Math.round((evaluatedAssessments / totalAssessments) * 100);
        }
    
        // For individual assessments
        if (!draft.subdomainScore || draft.subdomainScore.length === 0) return 0;
        const scoredSubdomains = draft.subdomainScore.filter(s => parseFloat(s.score) > 0).length;
        const totalDomains = draft.subdomainScore.length || 1;
        return Math.round((scoredSubdomains / totalDomains) * 100);
    };

    const handleDeleteDraft = async () => {
        if (!draftToDelete) return;

        try {
            const response = await fetch(
                `https://app.spiralreports.com/api/evaluations/${draftToDelete}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to delete draft');
            }

            await fetchDrafts();
            setIsDeleteModalOpen(false);
            setDraftToDelete(null);
            toast.success('Draft deleted successfully');
        } catch (err) {
            console.error('Error deleting draft:', err);
            toast.error('Failed to delete draft. Please try again.');
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        }).format(date);
    };

    const getRelativeTime = (dateString) => {
        if (!dateString) return '';
        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
        const date = new Date(dateString);
        const now = new Date();
        const diffTime = date.getTime() - now.getTime();
        const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) return 'Today';
        if (diffDays === -1) return 'Yesterday';
        if (diffDays > -7) return rtf.format(diffDays, 'day');
        return rtf.format(Math.round(diffDays / 7), 'week');
    };

  

    const getDraftTypeLabel = (draft) => {
        if (draft.is_packaged) {
            return (
                <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                    Group
                </span>
            );
        }
        return null;
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const toggleMoreMenu = (id) => {
        setIsMoreMenuOpen(prev => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [id]: !prev[id]
        }));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            Object.entries(menuRefs.current).forEach(([draftId, menuRef]) => {
                if (menuRef && !menuRef.contains(event.target)) {
                    setIsMoreMenuOpen(prev => ({ ...prev, [draftId]: false }));
                }
            });

            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsDeleteModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const filteredDrafts = drafts.filter(draft => {
        const matchesSearch = (draft.name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
                            (draft.title?.toLowerCase() || '').includes(searchTerm.toLowerCase());
        return matchesSearch;
    });

    return (
        <div className="min-h-screen bg-gray-50">
            <Header />
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <div className="bg-red-500 pt-20 pb-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6">
                    <button
                        className="flex items-center gap-2 text-white/90 hover:text-white mb-4"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowLeft className="w-4 h-4" />
                        <span>Back</span>
                    </button>
                    <h1 className="text-2xl font-bold text-white">Draft Assessments</h1>
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 sm:px-6 -mt-6 pb-52">
                <div className="bg-white rounded-xl shadow-sm">
                    {/* Custom Alert Component */}
                    {continuationError && (
                        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4 mx-4 mt-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-red-700">
                                        {continuationError}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="p-4 border-b border-gray-100">
                        <div className="flex flex-row gap-4">
                            <div className="flex-1 flex gap-4">
                                <div className="relative flex-1">
                                    <input
                                        type="text"
                                        placeholder="Search drafts..."
                                        className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {isLoading ? (
                        <div className="p-8 text-center">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-500 mx-auto"></div>
                            <p className="mt-4 text-gray-500">Loading draft assessments...</p>
                        </div>
                    ) : error ? (
                        <div className="p-8 text-center text-red-600">
                            {error}
                        </div>
                    ) : (
                        <>
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Name
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Description
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Last Modified
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Status
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Completion
                                            </th>
                                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {filteredDrafts.map((draft) => (
                                            <tr key={draft._id} className="hover:bg-gray-50">
                                                <td className="px-6 py-4">
                                                    <div className="flex items-center">
                                                        <div className="text-sm font-medium text-gray-900">
                                                            {draft.name || 'Untitled'}
                                                            {getDraftTypeLabel(draft)}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="text-sm text-gray-500">
                                                        {draft.description || '-'}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="text-sm text-gray-900">
                                                        {formatDate(draft.updatedAt)}
                                                    </div>
                                                    <div className="text-xs text-gray-500">
                                                        {getRelativeTime(draft.updatedAt)}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="flex items-center space-x-2">
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                            Draft
                                                        </span>
                                                        {draft.isGenerated && (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                                                Generated
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="flex items-center">
                                                        <div className="w-full bg-gray-200 rounded-full h-2 mr-2">
                                                            <div
                                                                className="bg-red-500 h-2 rounded-full"
                                                                style={{ width: `${calculateCompletion(draft)}%` }}
                                                            />
                                                        </div>
                                                        <span className="text-sm text-gray-500">
                                                            {calculateCompletion(draft)}%
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 text-right">
                                                    <div className="relative inline-block text-left">
                                                        <button
                                                            onClick={() => toggleMoreMenu(draft._id)}
                                                            className="p-2 text-gray-400 hover:text-gray-600 focus:outline-none"
                                                            disabled={continuationLoading}
                                                        >
                                                            <MoreVertical className="w-5 h-5" />
                                                        </button>
                                                        
                                                        {isMoreMenuOpen[draft._id] && (
                                                            <div
                                                                ref={el => menuRefs.current[draft._id] = el}
                                                                className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                                                            >
                                                                <div className="py-1">
                                                                    <button
                                                                        onClick={async () => {
                                                                            if (draft.is_packaged) {
                                                                                await handleGroupAssessmentContinuation(draft);
                                                                            } else {
                                                                                await handleContinueAssessment(draft.assessmentId, draft._id);
                                                                            }
                                                                        }}
                                                                        disabled={continuationLoading}
                                                                        className="flex items-center w-full px-4 py-2 text-sm text-green-600 hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                                                                    >
                                                                        <Clock className="w-4 h-4 mr-3" />
                                                                        {continuationLoading ? 'Loading...' : 'Continue Assessment'}
                                                                    </button>
                                                                    <button
                                                                        onClick={() => navigate(`/assessments/${draft._id}/edit`)}
                                                                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                    >
                                                                        <Edit2 className="w-4 h-4 mr-3" />
                                                                        Edit Details
                                                                    </button>
                                                                    <button
                                                                        onClick={() => {
                                                                            setDraftToDelete(draft._id);
                                                                            setIsDeleteModalOpen(true);
                                                                        }}
                                                                        className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                                                                    >
                                                                        <Trash2 className="w-4 h-4 mr-3" />
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {totalPages > 1 && (
                                <div className="flex justify-center items-center gap-2 p-4 border-t border-gray-200">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        className="px-3 py-1 text-sm rounded-lg disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-100"
                                    >
                                        Previous
                                    </button>
                                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                                        <button
                                            key={page}
                                            onClick={() => handlePageChange(page)}
                                            className={`px-3 py-1 text-sm rounded-lg ${
                                                currentPage === page
                                                    ? 'bg-red-500 text-white'
                                                    : 'hover:bg-gray-100'
                                            }`}
                                        >
                                            {page}
                                        </button>
                                    ))}
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                        className="px-3 py-1 text-sm rounded-lg disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-100"
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
                    <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>
                    
                    <div 
                        ref={modalRef}
                        className="relative z-50 bg-white rounded-lg max-w-md w-full mx-4 shadow-xl"
                    >
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-4">
                                <h3 className="text-lg font-semibold text-gray-900">
                                    Delete Draft Assessment
                                </h3>
                                <button
                                    onClick={() => setIsDeleteModalOpen(false)}
                                    className="text-gray-400 hover:text-gray-500 focus:outline-none"
                                >
                                    <X className="w-5 h-5" />
                                </button>
                            </div>
                            
                            <p className="text-sm text-gray-500 mb-6">
                                Are you sure you want to delete this draft assessment? This action cannot be undone.
                            </p>

                            <div className="flex justify-end gap-3">
                                <button
                                    onClick={() => {
                                        setIsDeleteModalOpen(false);
                                        setDraftToDelete(null);
                                    }}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDeleteDraft}
                                    className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DraftAssessmentsPage;