import React, { useState } from 'react';
import { Eye, EyeOff, Mail, Lock, Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { AUTH_ENDPOINTS } from '../../utils/constants';
import { generateStateParameter, encodeState } from '../../utils/oauth-utils';

const LoginForm = ({ onSubmit, formData, onInputChange, errorMessage: parentErrorMessage }) => {
  const navigate = useNavigate();
  const [touched, setTouched] = useState({
    username: false,
    password: false
  });
  
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isMicrosoftLoading, setIsMicrosoftLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const validateForm = () => {
    const errors = {
      username: { isValid: true, message: '' },
      password: { isValid: true, message: '' }
    };
    
    if (!formData.username) {
      errors.username = { isValid: false, message: 'Email is required' };
    } else if (!/\S+@\S+\.\S+/.test(formData.username)) {
      errors.username = { isValid: false, message: 'Please enter a valid email' };
    }
    
    if (!formData.password) {
      errors.password = { isValid: false, message: 'Password is required' };
    } else if (formData.password.length < 6) {
      errors.password = { isValid: false, message: 'Password must be at least 6 characters' };
    }
    
    return errors;
  };
  
  const validation = validateForm();
  const buttonEnabled = validation.username.isValid && validation.password.isValid;
  
  const handleBlur = (e) => {
    const { id } = e.target;
    setTouched(prev => ({
      ...prev,
      [id]: true
    }));
  };
  
  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  const handleRememberMe = () => {
    setRememberMe(prev => !prev);
  };

  const handleForgotPassword = () => {
    setIsModalOpen(true);
  };
  
  const handleSignUpClick = () => {
    navigate('/signup');
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleGoogleSignIn = async () => {
    try {
      setIsGoogleLoading(true);
      setLocalErrorMessage('');
      
      // Clear any existing state first
      sessionStorage.removeItem('googleOAuthState');
      
      console.log('Before state generation:', {
        sessionStorageKeys: Object.keys(sessionStorage),
        currentStoredState: sessionStorage.getItem('googleOAuthState')
      });
      
      // Generate state components
      const stateToken = generateStateParameter();
      const userId = formData.username || 'anonymous';
      
      console.log('Generated components:', {
        stateToken,
        userId
      });
      
      // Encode the state
      const encodedState = encodeState(userId, stateToken);
      
      console.log('Encoded state:', {
        encodedState,
        length: encodedState.length
      });
      
      // Store the full encoded state for verification
      sessionStorage.setItem('googleOAuthState', encodedState);
      
      // Verify storage immediately
      const storedState = sessionStorage.getItem('googleOAuthState');
      console.log('After storing:', {
        storedState,
        statesMatch: storedState === encodedState,
        sessionStorageKeys: Object.keys(sessionStorage)
      });
      
      // Construct Google login URL with proper parameters
      const params = new URLSearchParams({
        state: encodedState,
        redirect_uri: `${window.location.origin}/oauth/return`,
        response_type: 'code',
        scope: 'email profile'
      });
      
      const googleLoginUrl = `${AUTH_ENDPOINTS.GOOGLE_LOGIN}?${params.toString()}`;
      
      console.log('Redirecting with URL:', {
        googleLoginUrl,
        params: Object.fromEntries(params.entries())
      });
      
      // Small delay to ensure logs are visible
      await new Promise(resolve => setTimeout(resolve, 100));
      window.location.href = googleLoginUrl;
    } catch (error) {
      console.error('Google sign-in error:', error);
      setLocalErrorMessage('Failed to initialize Google sign-in. Please try again.');
      setIsGoogleLoading(false);
    }
  };

  const handleMicrosoftSignIn = async () => {
    try {
      setIsMicrosoftLoading(true);
      setLocalErrorMessage('');

      // Store the current URL for potential post-login redirect
      sessionStorage.setItem('preAuthPath', window.location.pathname);

      // Redirect to Microsoft login endpoint
      window.location.href = 'https://app.spiralreports.com/api/auth/user/login/m365';
    } catch (error) {
      console.error('Microsoft sign-in error:', error);
      setLocalErrorMessage('Failed to initialize Microsoft sign-in. Please try again.');
    } finally {
      setIsMicrosoftLoading(false);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!buttonEnabled || isSubmitting) return;
    
    setIsSubmitting(true);
    setLocalErrorMessage('');
    
    try {
      await onSubmit(e, { rememberMe });
    } catch (error) {
      setLocalErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const displayErrorMessage = parentErrorMessage || localErrorMessage;

  return (
    <div className="w-full max-w-md mx-auto p-6 space-y-8">
      <div className="text-center space-y-2">
        <h1 className="text-2xl font-bold text-gray-900">Welcome back</h1>
        <p className="text-gray-500">Please enter your work email to sign in</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-1">
          <label className="block text-sm font-medium text-gray-700" htmlFor="username">
            Work Email
          </label>
          <input
            id="username"
            type="email"
            placeholder="name@company.com"
            value={formData.username}
            onChange={onInputChange}
            onBlur={handleBlur}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 ${
              touched.username && !validation.username.isValid 
                ? 'border-red-500' 
                : 'border-gray-300'
            }`}
          />
          {touched.username && !validation.username.isValid && (
            <p className="text-sm text-red-500">{validation.username.message}</p>
          )}
        </div>

        <div className="space-y-1">
          <label className="block text-sm font-medium text-gray-700" htmlFor="password">
            Password
          </label>
          <div className="relative">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={formData.password}
              onChange={onInputChange}
              onBlur={handleBlur}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 ${
                touched.password && !validation.password.isValid 
                  ? 'border-red-500' 
                  : 'border-gray-300'
              }`}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
            >
              {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
            </button>
          </div>
          {touched.password && !validation.password.isValid && (
            <p className="text-sm text-red-500">{validation.password.message}</p>
          )}
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              type="checkbox"
              checked={rememberMe}
              onChange={handleRememberMe}
              className="h-4 w-4 text-red-500 focus:ring-red-500 border-gray-300 rounded"
            />
            <label htmlFor="remember-me" className="ml-2 text-sm text-gray-600">
              Remember me
            </label>
          </div>
          <button
            type="button"
            onClick={handleForgotPassword}
            className="text-sm text-red-500 hover:text-red-600"
          >
            Forgot password?
          </button>
        </div>

        {displayErrorMessage && (
          <div className="p-3 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-sm text-red-500">{displayErrorMessage}</p>
          </div>
        )}

        <button
          type="submit"
          disabled={!buttonEnabled || isSubmitting}
          className="w-full bg-red-500 text-white py-2 px-4 rounded-lg font-medium hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting ? (
            <span className="flex items-center justify-center gap-2">
              <Loader2 className="animate-spin h-5 w-5" />
              Signing in...
            </span>
          ) : (
            'Sign in'
          )}
        </button>

        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">Or continue with</span>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <button
            type="button"
            onClick={handleGoogleSignIn}
            disabled={isGoogleLoading}
            className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            {isGoogleLoading ? (
              <Loader2 className="animate-spin h-5 w-5" />
            ) : (
              <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24">
                <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
              </svg>
            )}
            <span>{isGoogleLoading ? 'Connecting...' : 'Google'}</span>
          </button>

          <button
            type="button"
            onClick={handleMicrosoftSignIn}
            disabled={isMicrosoftLoading}
            className="flex items-center justify-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            {isMicrosoftLoading ? (
              <Loader2 className="animate-spin h-5 w-5" />
            ) : (
              <svg className="h-5 w-5 mr-2" viewBox="0 0 23 23">
                <path fill="#f25022" d="M1 1h9v9H1z"/>
                <path fill="#00a4ef" d="M1 12h9v9H1z"/>
                <path fill="#7fba00" d="M12 1h9v9h-9z"/>
                <path fill="#ffb900" d="M12 12h9v9h-9z"/>
              </svg>
            )}
            <span>{isMicrosoftLoading ? 'Connecting...' : 'Microsoft'}</span>
          </button>
        </div>

        <div className="text-center text-sm">
          <span className="text-gray-500">Don't have an account?</span>{' '}
          <button
            type="button"
            onClick={handleSignUpClick}
            className="text-red-500 hover:text-red-600 font-medium"
          >
            Sign up
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;