import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Search, 
    MoreVertical, 
    Share2, 
    FileText, 
    ArrowLeft, 
    Trash2, 
    Shield, 
    Coins, 
    Package, 
    FileEdit,
    ChartBar,
    Users,
    CheckCircle 
} from 'lucide-react';
import useAssessments from '../hooks/useAssessments';
import useReport from '../hooks/useReport';
import TableLoader from '../components/ui/Table Loading Animation';
import PageLoader from '../components/ui/PageLoader';
import _ from 'lodash';

// Utility function to capitalize the first letter of a string
const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
};

// Framework Filter Component
const FrameworkFilter = ({ selectedFramework, setSelectedFramework, assessments }) => {
    const availableFrameworks = useMemo(() => {
        // Create a map to store unique frameworks with their original cases
        const frameworkMap = new Map();
        assessments?.forEach(assessment => {
            if (assessment.compliance) {
                const value = assessment.compliance.toLowerCase();
                const label = assessment.compliance; // Keep original case
                frameworkMap.set(value, label);
            }
        });

        // Convert to array and sort alphabetically
        return Array.from(frameworkMap.entries())
            .sort((a, b) => a[1].localeCompare(b[1]))
            .map(([value, label]) => ({ value, label }));
    }, [assessments]);

    return (
        <select
            className="px-4 py-2 border rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-red-500"
            value={selectedFramework}
            onChange={(e) => setSelectedFramework(e.target.value)}
        >
            <option value="all">All Frameworks</option>
            {availableFrameworks.map(({ value, label }) => (
                <option key={value} value={value}>
                    {label.toUpperCase()} {/* Display label in uppercase */}
                </option>
            ))}
        </select>
    );
};

// Frameworks Card Component
const FrameworksCard = ({ assessments, selectedFramework, setSelectedFramework }) => {
    const complianceCounts = useMemo(() => {
        // Create a map to maintain case sensitivity
        const countsMap = new Map();
        
        // Count occurrences while preserving original case
        assessments.forEach(assessment => {
            if (assessment.compliance) {
                const framework = assessment.compliance;
                countsMap.set(framework, (countsMap.get(framework) || 0) + 1);
            }
        });

        // Convert to array and sort
        return Array.from(countsMap.entries())
            .map(([framework, count]) => ({ framework, count }))
            .sort((a, b) => {
                // First by count (descending)
                const countDiff = b.count - a.count;
                if (countDiff !== 0) return countDiff;
                // Then alphabetically
                return a.framework.localeCompare(b.framework);
            })
            .slice(0, 3); // Get top 3
    }, [assessments]);

    return (
        <div className="bg-white p-6 rounded-xl shadow-sm">
            <div className="flex items-center gap-2 mb-4">
                <CheckCircle className="w-5 h-5 text-gray-400" />
                <h3 className="text-sm font-medium text-gray-500">Top Frameworks</h3>
            </div>
            <div className="grid grid-cols-3 gap-4">
                {complianceCounts.map(({ framework, count }) => (
                    <div
                        key={framework}
                        className={`text-center p-3 bg-gray-50 rounded-lg cursor-pointer transition-all ${
                            selectedFramework === framework.toLowerCase() ? 'border-2 border-blue-600' : 'hover:bg-gray-100'
                        }`}
                        onClick={() => {
                            const frameworkValue = framework.toLowerCase();
                            setSelectedFramework(selectedFramework === frameworkValue ? 'all' : frameworkValue);
                        }}
                    >
                        <div className="text-xl font-bold text-blue-600">{count}</div>
                        <div className="text-xs text-gray-500 mt-1 uppercase truncate" title={framework}>
                            {capitalize(framework)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const AssessmentsPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFramework, setSelectedFramework] = useState('all');
    const [selectedMaturity, setSelectedMaturity] = useState('all');
    const [selectedType, setSelectedType] = useState('all');
    const [isMoreMenuOpen, setIsMoreMenuOpen] = useState({});
    const [isDeleting, setIsDeleting] = useState(false);
    const [assessmentToDelete, setAssessmentToDelete] = useState(null);
    const { assessments, loading, deleteAssessment } = useAssessments();
    const { isLoading: reportLoading, fetchReport } = useReport();
    const navigate = useNavigate();
    const menuRefs = useRef({});

    // Calculate statistics
    const assessmentStats = useMemo(() => {
        if (!assessments?.length) return {
            total: 0,
            single: 0,
            group: 0,
            highScore: 0,
            mediumScore: 0,
            lowScore: 0
        };

        return {
            total: assessments.length,
            single: assessments.filter(a => !a.is_packaged).length,
            group: assessments.filter(a => a.is_packaged).length,
            highScore: assessments.filter(a => a.maturity >= 80).length,
            mediumScore: assessments.filter(a => a.maturity >= 60 && a.maturity < 80).length,
            lowScore: assessments.filter(a => a.maturity < 60).length
        };
    }, [assessments]);

    // Group assessments by package_id
    const groupedAssessments = useMemo(() => {
        return _.groupBy(assessments, (assessment) => {
            return assessment.is_packaged ? assessment.package_id : assessment.id;
        });
    }, [assessments]);

    // Filtered groups based on search and filters
    const filteredGroups = useMemo(() => {
        return Object.entries(groupedAssessments).reduce((acc, [key, group]) => {
            const primaryAssessment = group[0];
            const matchesSearch =
                primaryAssessment.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                primaryAssessment.name?.toLowerCase().includes(searchTerm.toLowerCase());

            const matchesFramework = selectedFramework === 'all' || (
                primaryAssessment.compliance?.toLowerCase() === selectedFramework.toLowerCase()
            );

            const matchesMaturity =
                selectedMaturity === 'all' ||
                (selectedMaturity === 'high' && primaryAssessment.maturity >= 80) ||
                (selectedMaturity === 'medium' && primaryAssessment.maturity >= 60 && primaryAssessment.maturity < 80) ||
                (selectedMaturity === 'low' && primaryAssessment.maturity < 60);

            const matchesType =
                selectedType === 'all' ||
                (selectedType === 'single' && group.length === 1) ||
                (selectedType === 'group' && group.length > 1);

            if (matchesSearch && matchesFramework && matchesMaturity && matchesType) {
                acc[key] = group;
            }
            return acc;
        }, {});
    }, [groupedAssessments, searchTerm, selectedFramework, selectedMaturity, selectedType]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            Object.entries(menuRefs.current).forEach(([key, menuRef]) => {
                if (menuRef && !menuRef.contains(event.target)) {
                    setIsMoreMenuOpen(prev => ({
                        ...prev,
                        [key]: false
                    }));
                }
            });
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getAssessmentBadge = (assessment, groupSize) => {
        if (groupSize > 1) {
            return (
                <div className="inline-flex items-center gap-1 px-2 py-0.5 bg-purple-100 text-purple-700 rounded-full text-xs font-medium">
                    <Package className="w-3 h-3" />
                    <span>Group ({groupSize})</span>
                </div>
            );
        } else if (assessment.is_draft) {
            return (
                <div className="inline-flex items-center gap-1 px-2 py-0.5 bg-amber-100 text-amber-700 rounded-full text-xs font-medium">
                    <FileEdit className="w-3 h-3" />
                    <span>Draft</span>
                </div>
            );
        }
        return null;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        }).format(date);
    };

    const getRelativeTime = (dateString) => {
        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
        const date = new Date(dateString);
        const now = new Date();
        const diffTime = date.getTime() - now.getTime();
        const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) return 'Today';
        if (diffDays === -1) return 'Yesterday';
        if (diffDays > -7) return rtf.format(diffDays, 'day');
        if (diffDays > -30) return rtf.format(Math.round(diffDays / 7), 'week');
        if (diffDays > -365) return rtf.format(Math.round(diffDays / 30), 'month');
        return rtf.format(Math.round(diffDays / 365), 'year');
    };

    const getMaturityColor = (score) => {
        if (score >= 80) return 'text-green-600';
        if (score >= 60) return 'text-yellow-600';
        return 'text-red-600';
    };

    const toggleMoreMenu = (id) => {
        setIsMoreMenuOpen(prev => {
            const newState = Object.keys(prev).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});
            newState[id] = !prev[id];
            return newState;
        });
    };

    const handleDeleteConfirmation = (assessmentId) => {
        setAssessmentToDelete(assessmentId);
        setIsDeleting(true);
    };

    const handleCancelDelete = () => {
        setAssessmentToDelete(null);
        setIsDeleting(false);
    };

    const handleDelete = async () => {
        if (assessmentToDelete) {
            try {
                await deleteAssessment(assessmentToDelete);
                setAssessmentToDelete(null);
                setIsDeleting(false);
            } catch (err) {
                console.error('Delete failed:', err);
                setIsDeleting(false);
            }
        }
    };

    const handleViewReport = async (assessment) => {
        if (reportLoading) return;

        try {
            if (assessment.is_packaged && assessment.package_eval_id) {
                navigate(`/group-report/${assessment.package_eval_id}`);
            } else {
                const reportData = await fetchReport(assessment.id);
                if (reportData) {
                    navigate(`/report/${assessment.id}`, { state: { reportData } });
                }
            }
        } catch (error) {
            console.error('Error fetching report:', error);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50">
          
            <div className="bg-red-500 pt-20 pb-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6">
                    <button
                        className="flex items-center gap-2 text-white/90 hover:text-white mb-4"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowLeft className="w-4 h-4" />
                        <span>Back</span>
                    </button>
                    <h1 className="text-2xl font-bold text-white">My Assessments</h1>
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 sm:px-6 -mt-6 pb-52">
                {/* Statistics Cards */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                    {/* Assessment Types Card */}
                    <div className="bg-white p-6 rounded-xl shadow-sm">
                        <div className="flex items-center gap-2 mb-4">
                            <Users className="w-5 h-5 text-gray-400" />
                            <h3 className="text-sm font-medium text-gray-500">Assessment Types</h3>
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            {/* Total Card (Non-Selectable) */}
                            <div className="text-center p-3 bg-gray-50 rounded-lg">
                                <div className="text-xl font-bold text-gray-900">{assessmentStats.total}</div>
                                <div className="text-xs text-gray-500 mt-1">Total</div>
                            </div>

                            {/* Single Card (Selectable) */}
                            <div
                                className={`text-center p-3 bg-gray-50 rounded-lg cursor-pointer transition-all ${
                                    selectedType === 'single' ? 'border-2 border-gray-900' : 'hover:bg-gray-100'
                                }`}
                                onClick={() => setSelectedType(selectedType === 'single' ? 'all' : 'single')}
                            >
                                <div className="text-xl font-bold text-gray-900">{assessmentStats.single}</div>
                                <div className="text-xs text-gray-500 mt-1">Single</div>
                            </div>

                            {/* Group Card (Selectable) */}
                            <div
                                className={`text-center p-3 bg-gray-50 rounded-lg cursor-pointer transition-all ${
                                    selectedType === 'group' ? 'border-2 border-gray-900' : 'hover:bg-gray-100'
                                }`}
                                onClick={() => setSelectedType(selectedType === 'group' ? 'all' : 'group')}
                            >
                                <div className="text-xl font-bold text-gray-900">{assessmentStats.group}</div>
                                <div className="text-xs text-gray-500 mt-1">Group</div>
                            </div>
                        </div>
                    </div>

                    {/* Maturity Scores Card */}
                    <div className="bg-white p-6 rounded-xl shadow-sm">
                        <div className="flex items-center gap-2 mb-4">
                            <ChartBar className="w-5 h-5 text-gray-400" />
                            <h3 className="text-sm font-medium text-gray-500">Maturity Scores</h3>
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            {/* High Score Card */}
                            <div
                                className={`text-center p-3 bg-gray-50 rounded-lg cursor-pointer transition-all ${
                                    selectedMaturity === 'high' ? 'border-2 border-green-600' : 'hover:bg-gray-100'
                                }`}
                                onClick={() => setSelectedMaturity(selectedMaturity === 'high' ? 'all' : 'high')}
                            >
                                <div className="text-xl font-bold text-green-600">{assessmentStats.highScore}</div>
                                <div className="text-xs text-gray-500 mt-1">High</div>
                            </div>

                            {/* Medium Score Card */}
                            <div
                                className={`text-center p-3 bg-gray-50 rounded-lg cursor-pointer transition-all ${
                                    selectedMaturity === 'medium' ? 'border-2 border-yellow-600' : 'hover:bg-gray-100'
                                }`}
                                onClick={() => setSelectedMaturity(selectedMaturity === 'medium' ? 'all' : 'medium')}
                            >
                                <div className="text-xl font-bold text-yellow-600">{assessmentStats.mediumScore}</div>
                                <div className="text-xs text-gray-500 mt-1">Medium</div>
                            </div>

                            {/* Low Score Card */}
                            <div
                                className={`text-center p-3 bg-gray-50 rounded-lg cursor-pointer transition-all ${
                                    selectedMaturity === 'low' ? 'border-2 border-red-600' : 'hover:bg-gray-100'
                                }`}
                                onClick={() => setSelectedMaturity(selectedMaturity === 'low' ? 'all' : 'low')}
                            >
                                <div className="text-xl font-bold text-red-600">{assessmentStats.lowScore}</div>
                                <div className="text-xs text-gray-500 mt-1">Low</div>
                            </div>
                        </div>
                    </div>

                    {/* Frameworks Card */}
                    <FrameworksCard
                        assessments={assessments}
                        selectedFramework={selectedFramework}
                        setSelectedFramework={setSelectedFramework}
                    />
                </div>

                {/* Main Content Area */}
                <div className="bg-white rounded-xl shadow-sm">
                    {/* Filters Section */}
                    <div className="p-4 border-b border-gray-100">
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex-1 flex flex-col md:flex-row gap-4">
                                {/* Search Input */}
                                <div className="relative flex-1">
                                    <input
                                        type="text"
                                        placeholder="Search assessments..."
                                        className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
                                </div>

                                {/* Framework Filter */}
                                <FrameworkFilter 
                                    selectedFramework={selectedFramework}
                                    setSelectedFramework={setSelectedFramework}
                                    assessments={assessments}
                                />
                            </div>

                            {/* Additional Filters */}
                            <div className="flex flex-col md:flex-row gap-4">
                                {/* Assessment Type Filter */}
                                <select
                                    className="px-4 py-2 border rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-red-500"
                                    value={selectedType}
                                    onChange={(e) => setSelectedType(e.target.value)}
                                >
                                    <option value="all">All Types</option>
                                    <option value="single">Single</option>
                                    <option value="group">Group</option>
                                </select>

                                {/* Maturity Score Filter */}
                                <select
                                    className="px-4 py-2 border rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-red-500"
                                    value={selectedMaturity}
                                    onChange={(e) => setSelectedMaturity(e.target.value)}
                                >
                                    <option value="all">All Scores</option>
                                    <option value="high">High (80%+)</option>
                                    <option value="medium">Medium (60-79%)</option>
                                    <option value="low">Low (&lt;60%)</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* Table Section */}
                    {loading ? (
                        <TableLoader />
                    ) : Object.keys(filteredGroups).length === 0 ? (
                        <div className="p-8 text-center">
                            <p className="text-gray-500">No assessments found matching your filters.</p>
                        </div>
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Name
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Framework & Tags
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Created
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Credits
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Maturity Score
                                        </th>
                                        <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {Object.entries(filteredGroups).map(([key, group]) => {
                                        const primaryAssessment = group[0];
                                        const isGrouped = group.length > 1;
                                        
                                        return (
                                            <tr key={key} className="hover:bg-gray-50 transition-colors">
                                                <td className="px-6 py-4">
                                                    <div className="flex flex-col gap-2">
                                                        <div className="flex items-center gap-2">
                                                            <span className="text-sm font-medium text-gray-900">
                                                                {primaryAssessment.name}
                                                            </span>
                                                            {getAssessmentBadge(primaryAssessment, group.length)}
                                                        </div>
                                                        <div className="text-sm text-gray-500">
                                                            {primaryAssessment.title}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="mb-2 flex items-center gap-1.5">
                                                        <Shield className="w-3.5 h-3.5 text-gray-400" />
                                                        <span className="text-sm text-gray-900">
                                                            {capitalize(primaryAssessment.compliance)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="text-sm text-gray-900">
                                                        {formatDate(primaryAssessment.createdAt)}
                                                    </div>
                                                    <div className="text-sm text-gray-500">
                                                        {getRelativeTime(primaryAssessment.createdAt)}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="flex items-center gap-1.5 text-sm text-gray-900">
                                                        <Coins className="w-4 h-4 text-gray-400" />
                                                        {group.reduce((sum, assessment) => sum + assessment.creditsUsed, 0)}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="flex flex-col items-start">
                                                        <span className={`text-lg font-bold ${getMaturityColor(primaryAssessment.maturity)}`}>
                                                            {primaryAssessment.maturity}%
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 text-right text-sm font-medium">
                                                    <div className="flex items-center justify-end gap-2">
                                                        <button
                                                            onClick={() => handleViewReport(primaryAssessment)}
                                                            className="px-3 py-1 text-sm text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors"
                                                            disabled={reportLoading}
                                                        >
                                                            {reportLoading ? 'Loading...' : isGrouped ? 'View Group Report' : 'View Report'}
                                                        </button>
                                                        <div className="relative">
                                                            <button
                                                                onClick={() => toggleMoreMenu(key)}
                                                                className="p-1 rounded-lg hover:bg-gray-100 transition-colors"
                                                                aria-label="More actions"
                                                            >
                                                                <MoreVertical className="w-4 h-4 text-gray-400" />
                                                            </button>
                                                            {isMoreMenuOpen[key] && (
                                                                <div 
                                                                    ref={el => menuRefs.current[key] = el}
                                                                    className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border z-10"
                                                                >
                                                                    <button 
                                                                        className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2 transition-colors"
                                                                    >
                                                                        <Share2 className="w-4 h-4" />
                                                                        Share Report
                                                                    </button>
                                                                    <button 
                                                                        className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 flex items-center gap-2 transition-colors"
                                                                    >
                                                                        <FileText className="w-4 h-4" />
                                                                        Download PDF
                                                                    </button>
                                                                    <button
                                                                        onClick={() => handleDeleteConfirmation(primaryAssessment.id)}
                                                                        className="w-full px-4 py-2 text-left text-red-600 text-sm hover:bg-gray-50 flex items-center gap-2 transition-colors"
                                                                    >
                                                                        <Trash2 className="w-4 h-4" />
                                                                        Delete Report
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>

            {reportLoading && <PageLoader />}

            {/* Delete Confirmation Modal */}
            {isDeleting && (
                <div className="fixed inset-0 flex items-center justify-center z-20">
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 transition-opacity"></div>
                    <div className="relative bg-white p-6 rounded-xl shadow-xl max-w-md w-full m-4">
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">
                            Are you sure you want to delete this assessment?
                        </h3>
                        <p className="text-sm text-gray-500 mb-6">
                            This action cannot be undone. All data associated with this assessment will be permanently removed.
                        </p>
                        <div className="flex justify-end gap-4">
                            <button
                                onClick={handleCancelDelete}
                                className="px-4 py-2 text-sm text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleDelete}
                                className="px-4 py-2 text-sm text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors"
                            >
                                Delete Assessment
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AssessmentsPage;