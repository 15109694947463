import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import logo1 from '../assets/SpiralReports Logo White.jpg';
import logo2 from '../assets/logoBlack.png';

const Header = ({ title }) => {
  const [scrolled, setScrolled] = useState(false);

  const navigate = useNavigate();
  const { authData } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navStyles = {
    backgroundColor: scrolled ? '#ffffff' : '#EF4444',
    boxShadow: scrolled ? '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' : 'none',
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: 1000,
    transition: 'all 0.3s ease',
    height: scrolled ? '70px' : '80px',
  };

  const containerStyles = {
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '0 2rem',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '2rem',
  };

  const titleStyles = {
    color: scrolled ? '#1f2937' : '#ffffff',
    fontSize: '1.25rem',
    fontWeight: '600',
    transition: 'all 0.3s ease',
    flex: 1,
    maxWidth: '500px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '5rem',
    paddingLeft: '2rem',
  };

  const creditsButtonStyles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: '0.3rem',
      backgroundColor: scrolled ? 'rgba(0, 0, 0, 0.045)' : 'rgba(255, 255, 255, 0.15)',
      borderRadius: '8px',
      color: scrolled ? '#1f2937' : '#ffffff',
      transition: 'all 0.2s ease',
      gap: '0.25rem',
    },
    credits: {
      padding: '0.45rem 0.75rem',
      fontSize: '0.938rem',
      fontWeight: '500',
      letterSpacing: '0.01em',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    divider: {
      width: '1px',
      height: '24px',
      backgroundColor: scrolled ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255, 255, 0.2)',
      margin: '0 0.2rem',
    },
    addButton: {
      display: 'flex',
      alignItems: 'center',
      padding: '0.45rem 0.75rem',
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '6px',
      color: scrolled ? '#1f2937' : '#ffffff',
      cursor: 'pointer',
      fontSize: '0.938rem',
      fontWeight: '500',
      transition: 'all 0.2s ease',
      gap: '0.5rem',
    },
  };

  const handleAddCreditsClick = () => {
    navigate('/add-credits');
  };

  const handleLogoClick = () => {
    navigate('/search');
  };

  const getCreditsHoverStyles = () => ({
    backgroundColor: scrolled ? 'rgba(0, 0, 0, 0.065)' : 'rgba(255, 255, 255, 0.2)',
  });

  const getAddButtonHoverStyles = () => ({
    backgroundColor: scrolled ? 'rgba(0, 0, 0, 0.065)' : 'rgba(255, 255, 255, 0.1)',
  });

  if (!authData) {
    return <div>Loading...</div>;
  }

  return (
    <nav style={navStyles}>
      <div style={containerStyles}>
        {/* Logo */}
        <img
          src={scrolled ? logo1 : logo2}
          alt="Logo"
          style={{
            height: scrolled ? '30px' : '55px',
            width: 'auto',
            transition: 'height 0.3s ease',
            cursor: 'pointer',
          }}
          onClick={handleLogoClick}
        />

        {/* Title */}
        {title && <h1 style={titleStyles}>{title}</h1>}

        {/* Combined Credits Section */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginLeft: 'auto' }}>
          <div
            style={{
              ...creditsButtonStyles.container,
              ...getCreditsHoverStyles(),
            }}
          >
            <div style={creditsButtonStyles.credits}>
              <svg
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {(authData && (authData.credits || authData.user?.credits))
                ? (authData.credits || authData.user?.credits).toLocaleString()
                : '0'}
            </div>
            <div style={creditsButtonStyles.divider} />
            <button
              onClick={handleAddCreditsClick}
              style={{
                ...creditsButtonStyles.addButton,
                ...getAddButtonHoverStyles(),
              }}
            >
              <svg
                style={{
                  width: '1rem',
                  height: '1rem',
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
              Add
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;