import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import { 
  ArrowLeft,
  BarChart3,
  FileSpreadsheet,
  AlertCircle,
  Timer
} from 'lucide-react';
import ReportGenerationModal from '../components/ReportGenerationModal'; // Import the modal component

// SectionHeader component
const SectionHeader = ({ title }) => (
  <h2 className="text-3xl font-bold mb-6">{title}</h2>
);

// SemiCircleProgress component
const SemiCircleProgress = ({ value, label, size = "lg", showLabel = true }) => {
  const getSize = () => {
    switch(size) {
      case "sm":
        return "w-24 h-12";
      case "lg":
      default:
        return "w-40 h-20";
    }
  };

  const getFontSize = () => {
    switch(size) {
      case "sm":
        return "text-lg";
      case "lg":
      default:
        return "text-2xl";
    }
  };

  const getMaturityColor = (score) => {
    if (score >= 80) return 'text-green-600';
    if (score >= 60) return 'text-yellow-600';
    return 'text-red-600';
  };

  return (
    <div className={`relative ${getSize()}`}>
      <div className="absolute inset-x-0 top-0 overflow-hidden rounded-t-full">
        <div 
          className="h-full bg-gray-100 w-full absolute" 
          style={{ 
            clipPath: 'path("M 0 100 A 100 100 0 0 1 200 100")',
            height: '100%'
          }} 
        />
        <div 
          className="h-full bg-red-500 w-full absolute" 
          style={{ 
            clipPath: 'path("M 0 100 A 100 100 0 0 1 200 100")',
            height: '100%',
            transform: `rotate(${180 + (value * 1.8)}deg)`,
            transformOrigin: '50% 100%',
            transition: 'transform 1s ease-out'
          }} 
        />
      </div>
      {showLabel && (
        <div className="absolute -bottom-2 inset-x-0 text-center">
          <span className={`${getFontSize()} font-semibold ${getMaturityColor(value)}`}>
            {value}%
          </span>
          {label && <div className="text-sm text-gray-500 mt-1">{label}</div>}
        </div>
      )}
    </div>
  );
};

// Circular progress component
const CircularProgress = ({ percentage, size = 'lg' }) => {
  const radius = size === 'lg' ? 35 : 25;
  const circumference = 2 * Math.PI * radius;
  const progress = ((100 - percentage) / 100) * circumference;
  const displayPercentage = Math.round(percentage);

  return (
    <div className="relative inline-flex">
      <svg className={size === 'lg' ? 'w-24 h-24' : 'w-16 h-16'}>
        <circle
          className="text-gray-100"
          strokeWidth="4"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={size === 'lg' ? '48' : '32'}
          cy={size === 'lg' ? '48' : '32'}
        />
        <circle
          className="text-red-500 -rotate-90 transform origin-center transition-all duration-1000 ease-out"
          strokeWidth="4"
          strokeDasharray={circumference}
          strokeDashoffset={progress}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={size === 'lg' ? '48' : '32'}
          cy={size === 'lg' ? '48' : '32'}
        />
      </svg>
      <span className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
        ${size === 'lg' ? 'text-lg' : 'text-sm'} font-medium`}>
        {displayPercentage}%
      </span>
    </div>
  );
};

const GroupReportPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { packageEvalId } = useParams();
  const { authData } = useAuth();
  const access_token = authData?.access_token || authData?.accessToken;

  // Initialize states
  const [packageDetails, setPackageDetails] = useState(null);
  const [assessments, setAssessments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState(false);
  const [error, setError] = useState(null);
  const [packageEvalData, setPackageEvalData] = useState(null);
  const [packageEvaluationId, setPackageEvaluationId] = useState(location.state?.packageEvaluationId || null);
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
  const [selectedAssessmentId, setSelectedAssessmentId] = useState(null); // State to store the selected assessment ID

  const newPackageEvalId = packageEvalId || location.state?.packageEvaluationId;

  // Fetch package evaluation data
  useEffect(() => {
    const fetchPackageEvalData = async () => {
      if (!newPackageEvalId || !access_token) {
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`https://app.spiralreports.com/api/package-eval/${newPackageEvalId}`, {
          headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch package evaluation data');
        }

        const responseData = await response.json();
        const evalData = responseData.data[0];

        if (!evalData) {
          throw new Error('No evaluation data found');
        }

        // Set package details
        const packageData = {
          _id: evalData._id,
          name: evalData.name,
          compliance: evalData.compliance,
          status: evalData.status,
          createdAt: evalData.createdAt,
          description: evalData.description
        };
        setPackageDetails(packageData);
        setPackageEvaluationId(evalData._id);

        // Set assessments
        if (evalData.assessments?.length > 0) {
          setAssessments(evalData.assessments);
        } else if (evalData.evaluations?.length > 0) {
          const transformedAssessments = evalData.evaluations.map(evaluation => ({
            _id: evaluation.assessmentId,
            title: evaluation.title || evaluation.name,
            description: evaluation.description,
            credReq: evaluation.maturity || 0,
            tags: evaluation.tags || []
          }));
          setAssessments(transformedAssessments);
        }

        setPackageEvalData(evalData);
      } catch (error) {
        console.error('Error fetching package evaluation data:', error);
        setError('Failed to fetch evaluation data');
      } finally {
        setLoading(false);
      }
    };

    fetchPackageEvalData();
  }, [newPackageEvalId, access_token]);

  // Calculate the overall maturity
  const calculateOverallMaturity = () => {
    if (!assessments?.length) return 0;
    
    const totalCredReq = assessments.reduce((sum, assessment) => sum + (assessment.credReq || 0), 0);
    const maxPossibleScore = assessments.length * 5;
    const maturityPercentage = (totalCredReq / maxPossibleScore) * 100;
    
    return Math.round(maturityPercentage);
  };

  // Handle View Report button click
  const handleViewReport = async (assessment) => {
    if (reportLoading) return;

    setReportLoading(true);
    setError(null);
    
    try {
      const matchingEvaluation = packageEvalData?.evaluations?.find(
        evaluation => evaluation.assessmentId === assessment._id
      );

      if (!matchingEvaluation) {
        throw new Error('Evaluation not found');
      }

      const evalId = matchingEvaluation._id;
      
      // Set the selected assessment ID and show the modal
      setSelectedAssessmentId(evalId);
      setIsModalVisible(true);

    } catch (error) {
      console.error('Error fetching report:', error);
      setError('Failed to fetch report data. Please try again.');
    } finally {
      setReportLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <div className="text-xl text-gray-600 mb-4">{error}</div>
          <button
            onClick={() => navigate('/dashboard')}
            className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Header Section */}
      <div className="bg-red-500 pt-20 pb-12">
        <div className="max-w-3xl mx-auto px-4 sm:px-6">
          <button 
            onClick={() => navigate('/dashboard')}
            className="flex items-center gap-2 text-white/90 hover:text-white mb-4"
          >
            <ArrowLeft className="w-4 h-4" />
            <span>Back</span>
          </button>
          <div className="flex items-center gap-3">
            <h1 className="text-2xl font-bold text-white">{packageDetails?.name}</h1>
            <span className="px-3 py-1 text-sm font-medium rounded-full bg-white/10 text-white">
              {packageDetails?.compliance}
            </span>
          </div>
          <p className="text-white/80 mt-2">{packageDetails?.description}</p>
        </div>
      </div>

      {/* Package Details Section */}
      <div className="max-w-3xl mx-auto px-4 sm:px-6 -mt-6">
        {/* Maturity Score Card */}
        <div className="bg-white rounded-xl shadow-sm mb-6">
          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-red-50 rounded-lg">
                  <BarChart3 className="w-5 h-5 text-red-500" />
                </div>
                <h2 className="text-lg font-semibold text-gray-900">Compliance Maturity</h2>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center mb-6">
              <SemiCircleProgress 
                value={calculateOverallMaturity()} 
                label="Overall Maturity"
              />
            </div>

            <div className="flex items-center justify-center gap-4 text-sm text-gray-500">
              <div className="flex items-center gap-1">
                <div className="w-2 h-2 bg-green-500 rounded-full" />
                <span>≥80% High Maturity</span>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-2 h-2 bg-yellow-500 rounded-full" />
                <span>60-79% Medium Maturity</span>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-2 h-2 bg-red-500 rounded-full" />
                <span>≤59% Low Maturity</span>
              </div>
            </div>
          </div>
        </div>

        {/* Status Banner */}
        {packageDetails?.status === 'IN_PROGRESS' && (
          <div className="bg-blue-50 border border-blue-100 rounded-xl p-4 mb-6 flex items-center gap-3">
            <Timer className="w-5 h-5 text-blue-500" />
            <p className="text-blue-700">
              Assessment evaluation is currently in progress. Some results may be incomplete.
            </p>
          </div>
        )}

        {/* Recommendation Section */}
        {packageEvalData?.recommendation && (
          <div className="bg-white rounded-xl shadow-sm mb-6 p-6">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-red-50 rounded-lg">
                <AlertCircle className="w-5 h-5 text-red-500" />
              </div>
              <h2 className="text-lg font-semibold text-gray-900">Recommendation</h2>
            </div>
            <p className="text-gray-600">{packageEvalData.recommendation}</p>
          </div>
        )}
      </div>

      {/* Assessment Details Section */}
      <div className="max-w-6xl mx-auto px-8 py-12">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Assessment Details</h2>
            <p className="text-gray-600 mt-1">
              Framework: {packageDetails?.compliance}
            </p>
          </div>
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
            <div className="w-2 h-2 bg-gray-300 rounded-full" />
              <span className="text-sm text-gray-600">
                Status: {packageDetails?.status}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 bg-gray-300 rounded-full" />
              <span className="text-sm text-gray-600">
                Created: {packageDetails?.createdAt && new Date(packageDetails.createdAt).toLocaleDateString()}
              </span>
            </div>
          </div>
        </div>

        {/* Assessment Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {assessments?.map((assessment) => (
            <div 
              key={assessment._id}
              className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-6"
            >
              <div className="flex flex-col">
                {/* Progress Circle */}
                <div className="mb-6">
                  <CircularProgress 
                    percentage={assessment.credReq ? (assessment.credReq / 5) * 100 : 20}
                    size="sm"
                  />
                </div>

                {/* Title and Category */}
                <div className="mb-6">
                  <h3 className="text-xl font-medium text-gray-900 mb-2">
                    {assessment.title}
                  </h3>
                  <h4 className="text-sm text-gray-600">
                    {assessment.category || 'Architecture'}
                  </h4>
                </div>

                {/* Tags */}
                <div className="mb-6">
                  <div className="flex flex-wrap gap-2">
                    {assessment.tags?.length > 0 ? (
                      assessment.tags.slice(0, 2).map((tag, index) => (
                        <span 
                          key={index}
                          className="px-3 py-1 bg-gray-100 text-gray-600 text-sm rounded-full"
                        >
                          {tag}
                        </span>
                      ))
                    ) : (
                      <>
                        <span className="px-3 py-1 bg-gray-100 text-gray-600 text-sm rounded-full">
                          Onboarding
                        </span>
                        <span className="px-3 py-1 bg-gray-100 text-gray-600 text-sm rounded-full">
                          Program
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/* Button */}
                <button 
                  onClick={() => handleViewReport(assessment)}
                  disabled={reportLoading}
                  className="w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Conclusion Section */}
      <div className="bg-red-500">
        <div className="max-w-4xl mx-auto px-4 py-16">
          <div className="text-white text-center mb-16">
            <SectionHeader title="Conclusion" />
            <p className="text-lg leading-relaxed max-w-3xl mx-auto">
              Our assessment provides a comprehensive view of your organization's compliance maturity. 
              Take action now to strengthen your security posture and meet industry standards.
            </p>
          </div>
          <div className="bg-white rounded-xl p-16 text-center shadow-2xl mx-auto max-w-3xl">
            <h3 className="text-gray-900 text-4xl font-bold mb-10">
              Ready To Enhance Your Compliance?
            </h3>
            <a 
              href="https://spiralreports.com/contact-us" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <button className="bg-red-500 text-white px-10 py-5 rounded-full hover:bg-red-600 transition-all duration-200 font-medium text-xl shadow-lg hover:shadow-xl transform hover:-translate-y-1">
                Schedule a consultation
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="fixed bottom-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      {/* Loading Overlay */}
      {reportLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white" />
        </div>
      )}

      {/* Report Generation Modal */}
      {isModalVisible && (
        <ReportGenerationModal 
          setIsVisible={setIsModalVisible} 
          assessmentId={selectedAssessmentId} 
        />
      )}
    </div>
  );
};

export default GroupReportPage;