import React, { useState, useEffect } from 'react';
import { 
  Book, Bookmark, BookmarkCheck, Users, 
  Clock, ChevronRight, ArrowLeft, Search, 
  Check, X, CreditCard, Filter, AlertTriangle, Layers,
  Building2, User
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import useUserProfile from '../hooks/useUserProfile';
import _ from 'lodash';
import Header from '../components/Header';
import PageLoader from '../components/ui/PageLoader';

const ROUTES = {
  ADD_CREDITS: '/add-credits',
  DASHBOARD: '/dashboard',
  QUIZ: '/assessmentsquiz',
};

// Alert Component
const CustomAlert = ({ message, onClose }) => (
  <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-4">
    <div className="flex items-center">
      <AlertTriangle className="h-4 w-4 text-red-500 mr-2" />
      <p className="text-sm text-red-700">{message}</p>
      {onClose && (
        <button 
          onClick={onClose} 
          className="ml-auto text-red-500 hover:text-red-700 p-1"
          aria-label="Close alert"
        >
          <X className="h-4 w-4" />
        </button>
      )}
    </div>
  </div>
);

// Credits Exhausted Modal
const CreditsExhaustedModal = ({ onClose, onAddCredits }) => (
  <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
    <div className="bg-white rounded-2xl p-6 max-w-md w-full mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold text-gray-900">Credits Exhausted</h3>
        <button 
          onClick={onClose}
          className="text-gray-400 hover:text-gray-500 transition-colors p-1"
          aria-label="Close modal"
        >
          <X className="w-5 h-5" />
        </button>
      </div>

      <div className="flex justify-center mb-6">
        <div className="p-3 bg-red-100 rounded-full">
          <CreditCard className="w-8 h-8 text-red-500" />
        </div>
      </div>

      <div className="text-center mb-8">
        <p className="text-gray-900 font-medium mb-2">
          You've used all your available credits
        </p>
        <p className="text-gray-500">
          Please add more credits to continue using our services
        </p>
      </div>

      <div className="flex flex-col gap-3 sm:flex-row-reverse">
        <button
          onClick={onAddCredits}
          className="flex-1 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition-colors"
        >
          Add Credits
        </button>
        <button
          onClick={onClose}
          className="flex-1 bg-gray-100 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-200 transition-colors"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
);

// Purpose Selection Modal Component
const PurposeSelectionModal = ({ onClose, onSelect }) => (
  <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
    <div className="bg-white rounded-2xl max-w-2xl w-full mx-auto">
      <div className="flex justify-between items-center p-6 border-b border-gray-100">
        <h2 className="text-xl font-semibold text-gray-900">Select Assessment Purpose</h2>
        <button 
          onClick={onClose}
          className="text-gray-400 hover:text-gray-500 transition-colors p-1"
          aria-label="Close modal"
        >
          <X className="w-5 h-5" />
        </button>
      </div>
      
      <div className="p-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {[
            {
              id: 'client',
              title: 'Client Assessment',
              description: 'Conduct assessment for a specific client or organization',
              icon: Building2
            },
            {
              id: 'personal',
              title: 'Personal Assessment',
              description: 'Take assessment for personal development or practice',
              icon: User
            }
          ].map((purpose) => {
            const Icon = purpose.icon;
            return (
              <button
                key={purpose.id}
                onClick={() => onSelect(purpose.id)}
                className="flex flex-col items-center p-6 border-2 border-gray-200 rounded-xl hover:border-red-500 hover:bg-red-50 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                <div className="p-3 bg-red-100 rounded-full mb-4">
                  <Icon className="h-6 w-6 text-red-600" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">{purpose.title}</h3>
                <p className="text-sm text-gray-600 text-center">{purpose.description}</p>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  </div>
);

// Assessment Name Modal Component
const AssessmentNameModal = ({ onClose, onSubmit, isGroup = false }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!name.trim()) {
      setError('Assessment name is required');
      return;
    }

    onSubmit({ name, description });
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-2xl max-w-lg w-full mx-auto">
        <div className="flex justify-between items-center p-6 border-b border-gray-100">
          <h2 className="text-xl font-semibold text-gray-900">
            {isGroup ? 'Enter Group Assessment Name' : 'Enter Assessment Name'}
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors p-1"
            aria-label="Close modal"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {error && (
            <div className="text-sm text-red-600 bg-red-50 p-3 rounded-lg mb-4">
              {error}
            </div>
          )}
          
          <div className="space-y-4">
            <div>
              <label htmlFor="assessment-name" className="block text-sm font-medium text-gray-700 mb-1">
                Assessment Name*
              </label>
              <input
                id="assessment-name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full rounded-lg border border-gray-200 px-4 py-2.5 text-gray-900 placeholder:text-gray-500 focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500"
                placeholder={isGroup ? "Enter group assessment name" : "Enter assessment name"}
              />
            </div>

            <div>
              <label htmlFor="assessment-description" className="block text-sm font-medium text-gray-700 mb-1">
                Description (Optional)
              </label>
              <textarea
                id="assessment-description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={4}
                className="block w-full rounded-lg border border-gray-200 px-4 py-2.5 text-gray-900 placeholder:text-gray-500 focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 resize-none"
                placeholder="Enter assessment description"
              />
            </div>
          </div>

          <div className="flex flex-col gap-3 sm:flex-row-reverse mt-6">
            <button
              type="submit"
              className="flex-1 bg-red-500 text-white py-2.5 px-4 rounded-lg hover:bg-red-600 transition-colors font-medium"
            >
              Continue
            </button>
            <button
              type="button"
              onClick={onClose}
              className="flex-1 bg-gray-100 text-gray-700 py-2.5 px-4 rounded-lg hover:bg-gray-200 transition-colors font-medium"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const calculateTotalTime = (questionCount) => {
  if (typeof questionCount !== 'number' || questionCount < 0) {
    throw new Error('Question count must be a non-negative number');
  }
  const totalTimeInSeconds = questionCount * 90;
  const totalTimeInMinutes = totalTimeInSeconds / 60;
  const roundedMinutes = Math.round(totalTimeInMinutes);
  return `${roundedMinutes} min`;
};

// MetricItem Component
const MetricItem = ({ icon, label, value }) => (
  <div className="flex items-center gap-2">
    <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-gray-50 flex-shrink-0">
      {icon}
    </div>
    <div className="flex flex-col min-w-0">
      <span className="text-xs text-gray-500">{label}</span>
      <span className="text-sm font-medium text-gray-900 truncate">
        {value}
      </span>
    </div>
  </div>
);

// Custom Tooltip Component
const CustomTooltip = ({ children, content, show }) => {
  if (!show) return children;
  
  return (
    <div className="group relative">
      {children}
      <div className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg w-64 z-50">
        <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-2 h-2 bg-gray-900 rotate-45" />
        {content}
      </div>
    </div>
  );
};

// Assessment Card Component
const AssessmentCard = ({ assessment, selected, onClick, disabled, isCompatible }) => {
  const displayTags = assessment.tags?.slice(0, 3) || [];
  const remainingTagsCount = (assessment.tags?.length || 0) - 3;

  return (
    <CustomTooltip 
      content="This assessment cannot be selected as it doesn't match the compliance type of currently selected assessments"
      show={disabled}
    >
      <div 
        className={`flex flex-col h-full bg-white rounded-xl border 
          ${disabled ? 'opacity-50 cursor-not-allowed' : 'border-gray-200 hover:shadow-lg'} 
          ${selected ? 'border-red-500' : ''}
          ${!isCompatible && !selected ? 'opacity-50' : ''}
          overflow-hidden transition-all duration-200`}
      >
        <div className="p-6 border-b border-gray-100">
          <div className="flex items-start justify-between gap-4 mb-4">
            <div className="flex items-start gap-3">
              <div className="mt-1 flex-shrink-0">
                <Layers className={`h-5 w-5 ${selected ? 'text-red-500' : 'text-gray-500'}`} />
              </div>
              <div className="min-w-0">
                <h3 className="text-lg font-semibold text-gray-900 leading-tight break-words line-clamp-2">
                  {assessment.title}
                </h3>
                {assessment.description && (
                  <p className="text-sm text-gray-500 mt-1 line-clamp-2">
                    {assessment.description}
                  </p>
                )}
              </div>
            </div>
            <div 
              className="flex-shrink-0 cursor-pointer"
              onClick={() => !disabled && onClick()} // Allow clicking the tick to deselect
            >
              <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                selected ? 'border-green-500 bg-green-500' : 'border-gray-300'
              }`}>
                {selected && <Check className="w-4 h-4 text-white" />}
              </div>
            </div>
          </div>

          <div className="flex flex-wrap gap-2 min-h-12">
            {displayTags.map((tag, index) => (
              <span
                key={index}
                className="inline-flex items-center px-3 py-1 rounded-full bg-red-50 text-red-600 text-xs font-medium"
              >
                {tag}
              </span>
            ))}
            {remainingTagsCount > 0 && (
              <span className="inline-flex items-center px-3 py-1 rounded-full bg-gray-100 text-gray-600 text-xs font-medium">
                +{remainingTagsCount} more
              </span>
            )}
          </div>
        </div>

        <div className="p-6 border-b border-gray-100 flex-1">
          <div className="grid grid-cols-2 gap-4">
            <MetricItem
              icon={<Book className="h-4 w-4 text-gray-500" />}
              label="Questions"
              value={assessment.questionCount}
            />
            <MetricItem
              icon={<Users className="h-4 w-4 text-gray-500" />}
              label="Evaluations"
              value={assessment.evaluationCount}
            />
            <MetricItem
              icon={<Clock className="h-4 w-4 text-gray-500" />}
              label="Duration"
              value={calculateTotalTime(assessment.questionCount)}
            />
            <MetricItem
              icon={<span className="text-sm font-semibold text-gray-500">©</span>}
              label="Credits"
              value={assessment.credReq}
            />
          </div>

          <div className="mt-4 flex items-center justify-between text-xs text-gray-500">
            <div className="flex items-center gap-2">
              {assessment.featured && (
                <span className="inline-flex items-center px-2 py-0.5 rounded bg-yellow-50 text-yellow-600">
                  Featured
                </span>
              )}
              {assessment.popular && (
                <span className="inline-flex items-center px-2 py-0.5 rounded bg-purple-50 text-purple-600">
                  Popular
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="p-6">
          <button
            onClick={() => !disabled && onClick()}
            disabled={disabled}
            className={`w-full flex items-center justify-center gap-2 px-4 py-3 rounded-lg
              ${disabled 
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                : selected
                  ? 'bg-red-50 hover:bg-red-100 text-red-600'
                  : 'bg-red-50 hover:bg-red-100 text-red-600'
              } font-medium transition-colors
              focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2`}
          >
            {selected ? 'Deselect' : disabled ? 'Incompatible Compliance' : 'Select Assessment'}
            <ChevronRight className="h-4 w-4" />
          </button>
        </div>
      </div>
    </CustomTooltip>
  );
};

// Main Assessment Flow Component
const AssessmentFlow = () => {
  const navigate = useNavigate();
  const { authData } = useAuth();
  const { userProfile } = useUserProfile();
  const access_token = authData?.access_token || authData?.accessToken;

  // State management
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCompliance, setSelectedCompliance] = useState('');
  const [complianceTypes, setComplianceTypes] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [showPurposeModal, setShowPurposeModal] = useState(false);
  const [showNameModal, setShowNameModal] = useState(false);
  const [selectedPurpose, setSelectedPurpose] = useState(null);

  // Fetch compliance types from API
  useEffect(() => {
    const fetchComplianceTypes = async () => {
      try {
        const response = await fetch(
          'https://app.spiralreports.com/api/assessments/compliance',
          {
            headers: {
              'Authorization': `Bearer ${access_token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) throw new Error('Failed to fetch compliance types');

        const { data } = await response.json();
        setComplianceTypes(data);
      } catch (err) {
        setError('Failed to fetch compliance types. Please try again.');
      }
    };

    fetchComplianceTypes();
  }, [access_token]);

  // Fetch assessments
  useEffect(() => {
    const fetchAssessments = async () => {
      setLoading(true);
      setError(null);

      try {
        // Construct the filter object based on the selected compliance
        const filter = selectedCompliance ? { compliance: selectedCompliance } : {};
        
        // Convert the filter object to a JSON string and URL-encode it
        const encodedFilter = encodeURIComponent(JSON.stringify(filter));

        // Make the API request with the filter
        const response = await fetch(
          `https://app.spiralreports.com/api/assessments/all?page=1&limit=1000&orderBy=asc&filter=${encodedFilter}`,
          {
            headers: {
              'Authorization': `Bearer ${access_token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) throw new Error('Failed to fetch assessments');

        const { data } = await response.json();
        setAssessments(data.results || []);
      } catch (err) {
        setError('Failed to fetch assessments. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchAssessments();
  }, [selectedCompliance, access_token]);

  // Handle assessment selection
  const handleAssessmentSelect = async (assessment) => {
    const availableCredits = parseInt(userProfile?.credits || '0', 10);
    if (availableCredits === 0) {
      setShowCreditsModal(true);
      return;
    }

    setSelectedAssessments(prev => {
      const isSelected = prev.some(item => item.id === assessment.id);

      if (isSelected) {
        return prev.filter(item => item.id !== assessment.id);
      }

      if (canSelectAssessment(assessment)) {
        return [...prev, assessment];
      }

      setError('Can only group assessments with matching compliance types');
      return prev;
    });
  };

  // Check if an assessment can be selected based on compliance matching
  const canSelectAssessment = (assessment) => {
    if (selectedAssessments.length === 0) return true;
    const firstSelectedCompliance = selectedAssessments[0].compliance;
    return assessment.compliance.some(compliance => 
      firstSelectedCompliance.includes(compliance)
    );
  };

  // Sort assessments to prioritize compatible ones
  const sortedAssessments = React.useMemo(() => {
    const filtered = assessments.filter(assessment =>
      assessment.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      assessment.description?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedAssessments.length === 0) return filtered;

    return _.orderBy(filtered, [
      assessment => canSelectAssessment(assessment) ? 0 : 1,
      assessment => selectedAssessments.some(selected => selected.id === assessment.id) ? 0 : 1,
    ]);
  }, [assessments, selectedAssessments, searchQuery]);

  // Handle add credits navigation
  const handleAddCredits = () => {
    setShowCreditsModal(false);
    navigate(ROUTES.ADD_CREDITS, { 
      state: { 
        returnPath: window.location.pathname,
        assessmentData: {
          selectedAssessments
        }
      }
    });
  };

  // Handle purpose selection
  const handlePurposeSelect = (purpose) => {
    setShowPurposeModal(false);
    setSelectedPurpose(purpose);
    setShowNameModal(true);
  };

  // Handle name and description submission
  const handleNameSubmit = async ({ name, description }) => {
    setShowNameModal(false);
    const recipient = selectedPurpose === 'client' ? 'CLIENT' : 'SELF';

    try {
      setLoading(true);

      if (selectedAssessments.length === 1) {
        const response = await fetch(
          `https://app.spiralreports.com/api/assessments/${selectedAssessments[0].id}`,
          {
            headers: {
              'Authorization': `Bearer ${access_token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) throw new Error('Failed to fetch assessment details');

        const result = await response.json();
        navigate(ROUTES.QUIZ, {
          state: {
            assessment: {
              ...result.data,
              name,
              description
            },
            accessToken: access_token,
            recipient,
            assessmentName: name,
            assessmentDescription: description
          }
        });
      } else {
        // Group assessment logic
        const response = await fetch('https://app.spiralreports.com/api/package', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            description,
            compliance: selectedAssessments[0].compliance[0].toUpperCase(),
            assessments: selectedAssessments.map(assessment => assessment.id),
            recipient
          })
        });

        if (!response.ok) throw new Error('Failed to create package');

        const packageResult = await response.json();

        const packageEvalResponse = await fetch('https://app.spiralreports.com/api/package-eval', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            description,
            package_id: packageResult.data._id,
            assessments: selectedAssessments.map(assessment => assessment.id),
            recipient
          })
        });

        if (!packageEvalResponse.ok) throw new Error('Failed to create package evaluation');

        const packageEvalResult = await packageEvalResponse.json();
        const packageEvalId = packageEvalResult.data._id;

        const detailsResponse = await fetch(
          `https://app.spiralreports.com/api/package-eval/${packageEvalId}`,
          {
            headers: {
              'Authorization': `Bearer ${access_token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!detailsResponse.ok) throw new Error('Failed to fetch package evaluation details');

        const packageDetails = await detailsResponse.json();

        navigate(ROUTES.QUIZ, {
          state: {
            packageData: {
              ...packageResult.data,
              name,
              description
            },
            packageEvalId,
            packageDetails: packageDetails.data[0],
            accessToken: access_token,
            isGroup: true,
            recipient
          }
        });
      }
    } catch (error) {
      setError('Failed to start assessment. Please try again.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle close action bar
  const handleCloseActionBar = () => {
    setSelectedAssessments([]);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {loading && <PageLoader />}
      <Header />

      {/* Main content */}
      <div className="max-w-7xl mx-auto px-8 pt-20 mt-8">
        <div className="space-y-6">
          {/* Search and Filters Section */}
          <div className="space-y-4">
            {/* Search Bar */}
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search assessments..."
                className="w-full px-6 py-4 pl-14 rounded-xl border border-gray-200 shadow-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
              />
              <Search className="absolute left-5 top-5 text-gray-400" size={20} />
            </div>

            {/* Filters */}
            <div className="bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden">
              <div className="flex items-center p-4">
                <div className="flex items-center gap-2">
                  <Filter className="w-5 h-5 text-gray-500" />
                  <span className="font-medium text-gray-900">Compliance Type</span>
                </div>
              </div>

              <div className="px-4 pb-4">
                <select
                  value={selectedCompliance}
                  onChange={(e) => setSelectedCompliance(e.target.value)}
                  className="w-full rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm text-gray-900 focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500"
                >
                  <option value="">All Compliance Types</option>
                  {complianceTypes.map((type) => (
                    <option key={type.compliance} value={type.compliance}>
                      {type.compliance}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Error Alert */}
          {error && <CustomAlert message={error} onClose={() => setError(null)} />}

          {/* Assessment Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pb-16">
            {loading ? (
              <div className="col-span-full flex items-center justify-center p-12">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-red-500" />
              </div>
            ) : sortedAssessments.length > 0 ? (
              sortedAssessments.map(assessment => (
                <AssessmentCard
                  key={assessment.id}
                  assessment={assessment}
                  selected={selectedAssessments.some(item => item.id === assessment.id)}
                  onClick={() => handleAssessmentSelect(assessment)}
                  disabled={selectedAssessments.length > 0 && 
                           !selectedAssessments.some(item => item.id === assessment.id) && 
                           !canSelectAssessment(assessment)}
                  isCompatible={canSelectAssessment(assessment)}
                />
              ))
            ) : (
              <div className="col-span-full flex flex-col items-center justify-center py-12 px-4">
                <AlertTriangle className="w-12 h-12 text-gray-300 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-1">No Assessments Found</h3>
                <p className="text-sm text-gray-500">Try adjusting your search criteria</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Selection Action Bar */}
      {selectedAssessments.length > 0 && (
        <div className="fixed bottom-4 left-1/2 -translate-x-1/2 bg-gray-200 text-black rounded-lg shadow-lg px-4 py-3 flex items-center gap-2 min-w-[400px]">
          {/* Selected count */}
          <div className="flex items-center gap-2">
            <span className="bg-gray-300 rounded-md px-2 py-1 text-sm">
              {selectedAssessments.length} selected
            </span>
          </div>
          {/* Actions */}
          <div className="flex-1 flex items-center justify-end gap-2">
            <button 
              onClick={() => setShowPurposeModal(true)}
              className="px-4 py-1.5 text-sm bg-red-500 hover:bg-red-600 text-white rounded-md transition-colors"
            >
              Start Assessment
            </button>
          </div>
          {/* Close button */}
          <button 
            onClick={handleCloseActionBar}
            className="p-1 text-black hover:bg-gray-300 rounded-md transition-colors"
          >
            <X size={20} />
          </button>
        </div>
      )}

      {/* Modals */}
      {showCreditsModal && (
        <CreditsExhaustedModal
          onClose={() => setShowCreditsModal(false)}
          onAddCredits={handleAddCredits}
        />
      )}

      {showPurposeModal && (
        <PurposeSelectionModal
          onClose={() => setShowPurposeModal(false)}
          onSelect={handlePurposeSelect}
        />
      )}

      {showNameModal && (
        <AssessmentNameModal
          onClose={() => setShowNameModal(false)}
          onSubmit={handleNameSubmit}
          isGroup={selectedAssessments.length > 1}
        />
      )}
    </div>
  );
};

export default AssessmentFlow;