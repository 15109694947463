import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './hooks/AuthContext';
import AuthPage from './pages/AuthPage';
import HomePage from './pages/Home';
import Assessment from './pages/SearchPage';
import ToastNotifications from './components/ToastNotifications';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './components/ProtectedRoute';
import AddCredit from './components/cards/AddCreditsModal';
import Settings from './pages/SettingsPage';
import AssessmentsPage from './pages/AssessmentsPage';
import AssessmentQuiz from './pages/AssessmentQuiz';
import PaymentCancelledScreen from './components/PaymentCancelledScreen';
import PaymentFailedScreen from './components/PaymentFailedScreen';
import PaymentSuccessScreen from './components/PaymentSuccessScreen';
import Dashboard from './pages/Dashboard';
import PasswordChangeScreen from './components/PasswordChangeScreen';
import AllInvoicePage from './pages/AllInvoicePage';
import SOC1Report from './pages/ReportPage';
import { UserProvider } from './context/UserContext';
import { isTokenExpired } from './utils/jwtUtils';
import { handleRefreshToken } from './utils/auth';
import DraftAssessmentsPage from './pages/DraftAssessment';
import ReferralPage from './pages/ReferralPage';
import VerifyEmailPage from './components/forms/signupforms/VerifyEmailPage';
import OAuthReturn from './pages/OAuthReturn';
import GroupAssessmentPage from './pages/GroupAssessmentPage';
import ReportPage from './pages/GroupReport';
import AssessmentFlow from './pages/AssessmentFlow';
import Signup from './components/signup';
import Layout from './components/Layout'; // Import the Layout component

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<AuthRedirect />} />
          <Route path="/login" element={<AuthPage />} />
          <Route path="/signup" element={<AuthPage />} />
          <Route path="/sign" element={<Signup />} />
          <Route path="/r/:referralCode" element={<AuthPage />} />
          <Route path="/verify-email" element={<VerifyEmailPage />} />
          <Route path="/oauth/return" element={<OAuthReturn />} />
          <Route path="/purchaseCancel" element={<PaymentCancelledScreen />} />
          <Route path="/purchaseFailed" element={<PaymentFailedScreen />} />
          <Route path="/purchaseSuccess" element={<PaymentSuccessScreen />} />
          <Route path="/reset-password" element={<PasswordChangeScreen />} />

          {/* Protected Routes with Layout */}
          <Route
            path="/search"
            element={
              <ProtectedRoute>
                <Layout>
                  <Assessment />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-credits"
            element={
              <ProtectedRoute>
                <Layout>
                  <AddCredit />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Layout>
                  <Settings />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/assessmentsPage"
            element={
              <ProtectedRoute>
                <Layout>
                  <AssessmentsPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/assessmentsquiz"
            element={
              <ProtectedRoute>
                <Layout>
                  <AssessmentQuiz />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/invoices"
            element={
              <ProtectedRoute>
                <Layout>
                  <AllInvoicePage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/report/:assessmentId"
            element={
              <ProtectedRoute>
                <Layout>
                  <SOC1Report />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/report"
            element={
              <ProtectedRoute>
                <Layout>
                  <SOC1Report />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/drafts"
            element={
              <ProtectedRoute>
                <Layout>
                  <DraftAssessmentsPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/referral"
            element={
              <ProtectedRoute>
                <Layout>
                  <ReferralPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-assessment"
            element={
              <ProtectedRoute>
                <Layout>
                  <GroupAssessmentPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-report"
            element={
              <ProtectedRoute>
                <Layout>
                  <ReportPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/group-report/:packageEvalId"
            element={
              <ProtectedRoute>
                <Layout>
                  <ReportPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/assessment-flow"
            element={
              <ProtectedRoute>
                <Layout>
                  <AssessmentFlow />
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
        <ToastNotifications />
        <ToastContainer />
      </UserProvider>
    </AuthProvider>
  );
}

// Component to handle redirection based on auth state
function AuthRedirect() {
  const { authData, setAuthData, isLoading } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthToken = async () => {
      try {
        const token = authData?.access_token || authData?.accessToken;
        const refreshToken = authData?.refreshToken;
        const userId = authData?.userId;

        if (!token) {
          setAuthData(null);
          navigate('/login');
          return;
        }

        if (isTokenExpired(token) && refreshToken && userId) {
          try {
            const newTokens = await handleRefreshToken(userId, refreshToken);
            setAuthData({
              access_token: newTokens.accessToken,
              refreshToken: newTokens.refreshToken,
              userId,
            });
          } catch (error) {
            setAuthData(null);
            navigate('/login');
          }
        }
      } catch (error) {
        setAuthData(null);
        navigate('/login');
      }
    };

    if (!isLoading) {
      checkAuthToken();
    }
    setLoading(false);
  }, [authData, isLoading, setAuthData, navigate]);

  if (loading) return <div>Loading...</div>;
  if (authData) return <Navigate to="/dashboard" replace />;
  return <HomePage />;
}

export default App;