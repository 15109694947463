/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Plus, Check, Grid, List, Save } from 'lucide-react';
import useSubmitAssessment from '../hooks/useSubmitAssessment';
import Header from '../components/Header';
import ReportGenerationModal from '../components/ReportGenerationModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// ProgressBar Component
const ProgressBar = ({ progress }) => (
  <div className="w-full">
    <div className="flex justify-between text-sm text-gray-500  mb-2">
      <span>Progress</span>
      <span>{Math.round(progress)}%</span>
    </div>
    <div className="w-full h-1 bg-gray-100 rounded overflow-hidden">
      <div 
        className="h-full bg-red-500 transition-all duration-300" 
        style={{ width: `${progress}%` }} 
      />
    </div>
  </div>
);

// Group Report Generation Modal Component
const GroupReportGenerationModal = ({ setIsVisible, packageDetails, packageEvalId }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    if (packageDetails) {
      const timer = setTimeout(() => {
        navigate('/group-report', {
          state: {
            packageDetails,
            assessments: packageDetails.assessments,
            packageEvaluationId: packageDetails.packageEvalId || packageEvalId
          }
        });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, packageDetails, packageEvalId]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-xl p-8 max-w-md w-full mx-4">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-500 mx-auto mb-4" />
          <h3 className="text-lg font-semibold text-gray-900 mb-2">
            Processing Group Assessment
          </h3>
          <p className="text-gray-500">
            We're processing your group assessment results...
          </p>
        </div>
      </div>
    </div>
  );
};

const AssessmentQuiz = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { 
    assessment: initialAssessment, 
    packageData,
    existingEvaluation,
    packageEvalId: initialPackageEvalId,
    remainingAssessments: initialRemainingAssessments,
    accessToken,
    recipient // Add recipient from location state
  } = location.state || {};
  console.log(recipient);
  
  // Function to check for new assessments
  const checkForNewAssessments = (assessments) => {
    if (!assessments || !Array.isArray(assessments)) return [];
    return assessments.map(assessment => ({
      ...assessment,
      new: assessment.new ?? !existingEvaluation
    }));
  };

  // Initialize state variables
  const [assessments, setAssessments] = useState(() => {
    if (initialAssessment && initialRemainingAssessments) {
      const allAssessments = [initialAssessment, ...initialRemainingAssessments];
      return checkForNewAssessments(allAssessments);
    } else if (initialAssessment) {
      return checkForNewAssessments([initialAssessment]);
    }
    return [];
  });

  // Quiz state
  const [currentAssessmentIndex, setCurrentAssessmentIndex] = useState(0);
  const [loadingAssessment, setLoadingAssessment] = useState(!!packageData?.assessments?.length);
  const [isGridView, setIsGridView] = useState(() => {
    const saved = localStorage.getItem('viewPreference');
    return saved !== null ? JSON.parse(saved) : true;
  });

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    if (existingEvaluation?.response) {
      const firstUnansweredIndex = existingEvaluation.response.findIndex(
        question => !question.options?.length
      );
      return firstUnansweredIndex === -1 ? 0 : firstUnansweredIndex;
    }
    return 0;
  });

  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (!existingEvaluation?.response) return {};
    
    return existingEvaluation.response.reduce((acc, response, index) => {
      if (response.options && response.options.length > 0) {
        acc[index] = response.options.map(opt => opt.text);
      }
      return acc;
    }, {});
  });

  // Tool management state
  const [tools, setTools] = useState(existingEvaluation?.toolsUsed || []);
  const [currentTool, setCurrentTool] = useState('');
  const [showToolsPage, setShowToolsPage] = useState(false);
  
  // Status and error handling state
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [packageEvalId] = useState(initialPackageEvalId);
  const [assessmentId, setAssessmentId] = useState(null);
  const [savingDraft, setSavingDraft] = useState(false);
  const [draftSaved, setDraftSaved] = useState(false);
  const [completedAssessments, setCompletedAssessments] = useState([]);
  const [showGroupReportModal, setShowGroupReportModal] = useState(false);
  const [groupReportData, setGroupReportData] = useState(null);

  // Get submit assessment hook
  const { submitAssessment, loading: submitLoading } = useSubmitAssessment();

  // Derived values
  const currentAssessment = assessments[currentAssessmentIndex];
  const isNewAssessment = currentAssessment?.new || false;
  const questionSet = currentAssessment?.questionSet || [];
  const currentQuestion = questionSet[currentQuestionIndex];
  const isGroupAssessment = Boolean(packageData);
  const totalAssessments = assessments.length;

  // Initialize assessments
  useEffect(() => {
    const initializeAssessments = async () => {
      if (assessments.length > 0) {
        setLoadingAssessment(false);
        return;
      }

      if (packageData?.assessments?.length) {
        setLoadingAssessment(true);
        try {
          const promises = packageData.assessments.map(async (assessmentId) => {
            const response = await fetch(
              `https://app.spiralreports.com/api/assessments/${assessmentId}`,
              {
                headers: {
                  'Authorization': `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            if (!response.ok) {
              throw new Error('Failed to fetch assessment');
            }
            const { data } = await response.json();
            return data;
          });

          const fetchedAssessments = await Promise.all(promises);
          const assessmentsWithFlag = checkForNewAssessments(fetchedAssessments);
          setAssessments(assessmentsWithFlag);
        } catch (error) {
          console.error('Error fetching assessments:', error);
          toast.error('Failed to load assessments. Please try again.');
        } finally {
          setLoadingAssessment(false);
        }
      }
    };

    initializeAssessments();
  }, [packageData, accessToken, assessments.length]);

  // Save assessment progress
  const saveAssessmentProgress = async (assessmentData) => {
    try {
      // For new assessments, always use submitAssessment
      if (isNewAssessment) {
        const result = await submitAssessment(assessmentData);
        return result.data;
      }

      // For existing assessments that are not new
      if (existingEvaluation?.id) {
        const draftResponse = await fetch(
          `https://app.spiralreports.com/api/evaluations/${existingEvaluation.id}`,
          {
            method: 'PATCH',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(assessmentData)
          }
        );

        if (!draftResponse.ok) {
          throw new Error('Failed to submit assessment');
        }

        const result = await draftResponse.json();
        return result.data;
      } else {
        // Fallback to submitAssessment if no existing evaluation
        const result = await submitAssessment(assessmentData);
        return result.data;
      }
    } catch (error) {
      throw error;
    }
  };

  // Submit handler
  const handleSubmit = async () => {
    try {
      const currentAnswers = Object.values(selectedOptions).flat();
      if (currentAnswers.length === 0) {
        setErrorMessage('Please answer at least one question before submitting.');
        return;
      }

      const assessmentData = {
        assessmentId: currentAssessment?.id,
        recipient: recipient || 'SELF', // Use passed recipient value
        name: currentAssessment?.title,
        compliance: isGroupAssessment 
          ? packageData?.compliance 
          : currentAssessment?.compliance?.[0],
        questions: questionSet.map((question, questionIndex) => ({
          question: question.question,
          considerScore: question.considerScore,
          options: (selectedOptions[questionIndex] || [])
            .map(optionText => question.options.find(option => option.text === optionText))
            .filter(Boolean)
            .map(option => ({
              text: option.text,
              score: option.score || 0,
              weight: option.weight || 1,
              level: option.level || null
            }))
        })),
        tools,
        is_packaged: isGroupAssessment,
        is_draft: false,
        package_id: packageData?._id || null,
        package_eval_id: packageEvalId || null,
        new: isNewAssessment
      };

      const result = await saveAssessmentProgress(assessmentData);

      if (isGroupAssessment) {
        setCompletedAssessments(prev => [...prev, result.id]);

        if (currentAssessmentIndex < totalAssessments - 1) {
          setCurrentAssessmentIndex(prev => prev + 1);
          setCurrentQuestionIndex(0);
          setSelectedOptions({});
          setShowToolsPage(false);
          toast.success("Assessment submitted successfully! Moving to next assessment.");
        } else {
          await handleGroupCompletion();
        }
      } else {
        setAssessmentId(result.id);
        setIsModalVisible(true);
      }
    } catch (error) {
      console.error('Error submitting assessment:', error);
      setErrorMessage('Failed to submit assessment. Please try again.');
      toast.error("Failed to submit assessment. Please try again.");
    }
  };

  // Handle group completion
  const handleGroupCompletion = async () => {
    try {
      const detailsResponse = await fetch(
        `https://app.spiralreports.com/api/package-eval/${packageEvalId}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!detailsResponse.ok) {
        throw new Error('Failed to fetch package evaluation details');
      }

      const packageDetails = await detailsResponse.json();
      
      if (!packageDetails?.data?.[0]) {
        throw new Error('Invalid package details received');
      }

      const evalData = packageDetails.data[0];
      
      setGroupReportData({
        packageDetails: {
          _id: evalData._id,
          name: evalData.name,
          compliance: evalData.compliance,
          status: evalData.status,
          createdAt: evalData.createdAt,
          description: evalData.description
        },
        assessments: evalData.assessments
      });

      setShowGroupReportModal(true);
    } catch (error) {
      console.error('Error completing package evaluation:', error);
      setErrorMessage('Failed to complete package evaluation.');
      toast.error("Failed to complete package evaluation. Please try again.");
    }
  };

  // Save draft handler
  const handleSaveDraft = async () => {
    if (savingDraft) return;
    
    setSavingDraft(true);
    setErrorMessage('');
    
    try {
      const currentAnswers = Object.values(selectedOptions).flat();
      
      if (currentAnswers.length === 0) {
        setErrorMessage('Please answer at least one question to save draft');
        return;
      }

      const draftData = {
        assessmentId: currentAssessment?.id,
        recipient: 'SELF',
        name: currentAssessment?.title,
        compliance: isGroupAssessment 
          ? packageData?.compliance 
          : currentAssessment?.compliance?.[0],
        questions: questionSet.map((question, questionIndex) => ({
          question: question.question,
          considerScore: question.considerScore,
          options: (selectedOptions[questionIndex] || [])
            .map(optionText => question.options.find(option => option.text === optionText))
            .filter(Boolean)
            .map(option => ({
              text: option.text,
              score: option.score || 0,
              weight: option.weight || 1
            }))
        })),
        tools,
        is_draft: true,
        is_packaged: isGroupAssessment,
        package_id: packageData?._id || null,
        package_eval_id: packageEvalId || null,
        new: isNewAssessment
      };

      await saveAssessmentProgress(draftData);

      setDraftSaved(true);
      toast.success("Draft saved successfully!");

      setTimeout(() => {
        navigate('/search');
      }, 1500);

    } catch (error) {
      console.error('Error saving draft:', error);
      setErrorMessage('Failed to save draft. Please try again.');
      toast.error("Failed to save draft. Please try again.");
    } finally {
      setSavingDraft(false);
    }
  };

  // Option selection handler
  const handleOptionToggle = (optionText) => {
    setSelectedOptions(prev => {
      const currentQuestionSelections = prev[currentQuestionIndex] || [];
      const newSelections = currentQuestionSelections.includes(optionText)
        ? currentQuestionSelections.filter(text => text !== optionText)
        : [...currentQuestionSelections, optionText];
        
      return {
        ...prev,
        [currentQuestionIndex]: newSelections
      };
    });
    setErrorMessage('');
  };

  // Navigation handlers
  const handleNextQuestion = () => {
    if ((selectedOptions[currentQuestionIndex] || []).length === 0) {
      setErrorMessage('Please select at least one option to proceed.');
      return;
    }

    setErrorMessage('');
    if (currentQuestionIndex === questionSet.length - 1) {
      setShowToolsPage(true);
    } else {
      setCurrentQuestionIndex(prev => prev + 1);
    }
  };

  const handlePreviousQuestion = () => {
    setErrorMessage('');
    if (showToolsPage) {
      setShowToolsPage(false);
    } else if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };

  const calculateProgress = () => {
    if (!questionSet?.length) return 0;
    const answeredQuestions = Object.keys(selectedOptions).length;
    return (answeredQuestions / questionSet.length) * 100;
  };

  // Tools management
  const handleToolSubmit = (e) => {
    e.preventDefault();
    if (currentTool.trim() === '') {
      setErrorMessage('Tool name cannot be empty.');
      return;
    }
    if (tools.includes(currentTool.trim())) {
      setErrorMessage('Tool already added.');
      return;
    }
    setTools([...tools, currentTool.trim()]);
    setCurrentTool('');
    setErrorMessage('');
  };

  const handleToolDelete = (toolToDelete) => {
    setTools(tools.filter(tool => tool !== toolToDelete));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleToolSubmit(e);
    }
  };

  if (loadingAssessment) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-500" />
      </div>
    );
  }

  // Check if we have questions loaded
  if (!currentAssessment || !questionSet.length) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-800 mb-2">No Questions Available</h2>
          <p className="text-gray-600">Unable to load assessment questions. Please try again.</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
 <Header title={currentAssessment?.title } />     
  <div className="max-w-4xl mx-auto px-4 pt-24 pb-6 min-h-screen flex flex-col">
        
        
    
        
        {/* Progress indicators */}       
        {isGroupAssessment && (
          <div className="mb-6">
            <div className="text-sm text-gray-500 mb-2">
              Assessment {currentAssessmentIndex + 1} of {assessments.length}
            </div>
            <div className="w-full h-2 bg-gray-100 rounded-full">
              <div 
                className="h-full bg-red-500 rounded-full transition-all duration-300"
                style={{ width: `${(currentAssessmentIndex / assessments.length) * 100}%` }}
              />
            </div>
          </div>
        )}

        <div className="bg-white rounded-xl shadow-md p-2 mb-16 flex-grow">
          <div className="sticky top-0 bg-white z-10 px-4 pt-2">
            <ProgressBar progress={calculateProgress()} />
            {!showToolsPage && currentQuestion && (
              <>
                <div className="text-xs font-semibold text-red-500 uppercase tracking-wider mb-2">
                  Question {currentQuestionIndex + 1} of {questionSet.length}
                </div>
                <div className="text-lg font-semibold text-gray-900 mb-6 leading-relaxed">
                  {currentQuestion?.question}
                </div>
                
                {/* View toggle buttons */}
                <div className="flex justify-between items-center mb-6">
                  <div className="flex items-center">
                    <p className="text-sm text-gray-500 mr-2">View answers as:</p>
                    <button
                      onClick={() => setIsGridView(true)}
                      className={`p-2 rounded-lg flex items-center gap-2 ${
                        isGridView ? 'bg-red-100 text-red-600' : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }`}
                    >
                      <Grid size={18} />
                      <span className="text-sm font-medium">Grid</span>
                    </button>
                    <button
                      onClick={() => setIsGridView(false)}
                      className={`p-2 rounded-lg flex items-center gap-2 ml-2 ${
                        !isGridView ? 'bg-red-100 text-red-600' : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }`}
                    >
                      <List size={18} />
                      <span className="text-sm font-medium">List</span>
                    </button>
                  </div>
                  <div className="text-sm text-gray-500">
                    {(selectedOptions[currentQuestionIndex] || []).length} selected
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Main content */}
          <div className="mb-20">
            {!showToolsPage && currentQuestion ? (
              <div className={`${
                isGridView 
                  ? 'grid grid-cols-1 md:grid-cols-2 gap-3' 
                  : 'flex flex-col gap-3'
              }`}>
                {currentQuestion.options?.map((option, index) => (
                  <div
                    key={index}
                    onClick={() => handleOptionToggle(option.text)}
                    className={`border rounded-lg cursor-pointer transition-all duration-200 overflow-hidden            
                      ${(selectedOptions[currentQuestionIndex] || []).includes(option.text) 
                        ? 'bg-red-50 border-red-200' 
                        : 'bg-white border-gray-200'}`}
                  >
                    <div className="flex items-start p-4 gap-3">
                      <div className={`w-5 h-5 rounded border-2 flex-shrink-0 mt-0.5 relative transition-all duration-200
                        ${(selectedOptions[currentQuestionIndex] || []).includes(option.text) 
                          ? 'bg-red-500 border-red-500' 
                          : 'border-gray-300'}`}
                      >
                        {(selectedOptions[currentQuestionIndex] || []).includes(option.text) && 
                          <Check size={16} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white" />
                        }
                      </div>
                      <span className="text-gray-700 text-base leading-relaxed">{option.text}</span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
              <h2 className="text-2xl font-semibold text-gray-900 mb-2 pl-3.5 pt-4">
  Tools used in organisation
</h2>
<p className="text-base text-gray-500 mb-6 leading-relaxed pl-3.5">
  Please list all the tools that are currently being used in your organization for asset management and security.
</p>

                <div className="relative w-full mb-6">
                  <input
                    type="text"
                    value={currentTool}
                    onChange={(e) => setCurrentTool(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type a tool name"
                    className="w-full p-3 rounded-lg border border-gray-300 text-base transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
                  />
                  {currentTool && (
                    <button
                      onClick={handleToolSubmit}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      title="Add tool"
                    >
                      <Plus size={16} className="text-gray-500 hover:text-gray-700" />
                    </button>
                  )}
                </div>

                <div className="flex flex-wrap gap-4 min-h-8">
                  {tools.map((tool, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center bg-red-100 px-6 py-3 rounded-lg text-base text-red-900 font-medium shadow-md mb-2"
                    >
                      {tool}
                      <button
                        onClick={() => handleToolDelete(tool)}
                        className="ml-3 text-red-900 hover:text-red-800 text-xl p-1"
                        title="Remove tool"
                      >
                        ×
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            )}

            {errorMessage && (
              <div className="text-red-500 text-sm mb-4 mt-4">{errorMessage}</div>
            )}
          </div>

          {/* Navigation buttons */}
          <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
            <div className="max-w-4xl mx-auto flex justify-between gap-4">
              <div className="flex gap-4">
                <button
                  onClick={handlePreviousQuestion}
                  disabled={currentQuestionIndex === 0 && !showToolsPage}
                  className={`px-6 py-3 rounded-md text-sm font-medium border border-gray-300 transition-all duration-200
                    ${currentQuestionIndex === 0 && !showToolsPage ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
                >
                  Previous
                </button>
                
                <button
                  onClick={handleSaveDraft}
                  disabled={savingDraft || Object.keys(selectedOptions).length === 0}
                  className={`px-6 py-3 rounded-md text-sm font-medium border border-gray-300 flex items-center gap-2 transition-all duration-200
                    ${savingDraft ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
                >
                  <Save size={18} />
                  {savingDraft ? 'Saving...' : draftSaved ? 'Saved!' : 'Save Draft'}
                </button>
              </div>

              {!showToolsPage ? (
                <button
                  onClick={handleNextQuestion}
                  disabled={(selectedOptions[currentQuestionIndex] || []).length === 0}
                  className={`px-6 py-3 rounded-md text-sm font-medium bg-red-500 text-white hover:bg-red-600 transition-all duration-200
                    ${(selectedOptions[currentQuestionIndex] || []).length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  disabled={submitLoading}
                  className="px-6 py-3 rounded-md text-sm font-medium bg-red-500 text-white hover:bg-red-600 transition-all duration-200 disabled:opacity-50"
                >
                  {submitLoading 
                    ? 'Submitting...' 
                    : isGroupAssessment 
                      ? `Submit Assessment ${currentAssessmentIndex + 1}/${assessments.length}`
                      : 'Submit Assessment'
                  }
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Modals */}
        {isModalVisible && !isGroupAssessment && (
          <ReportGenerationModal 
            setIsVisible={setIsModalVisible}
            assessmentId={assessmentId}
          />
        )}

        {showGroupReportModal && groupReportData && (
          <GroupReportGenerationModal 
            setIsVisible={setShowGroupReportModal}
            packageDetails={groupReportData}
            packageEvalId={packageEvalId}
          />
        )}
      </div>
    </div>
  );
};

export default AssessmentQuiz;