import { Buffer } from 'buffer';

export class OAuthError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'OAuthError';
    this.code = code;
  }
}

export const generateStateParameter = () => {
  const randomString = Math.random().toString(36).substring(2);
  const timestamp = Date.now().toString(36);
  return `${randomString}${timestamp}`;
};

export const encodeState = (userId, token) => {
  const stateString = `${userId}$:$${token}`;
  return Buffer.from(stateString).toString('base64');
};

export const decodeState = (encodedState) => {
  try {
    const decodedString = Buffer.from(encodedState, 'base64').toString('utf-8');
    const [userId, token] = decodedString.split('$:$');
    
    if (!userId || !token) {
      throw new OAuthError('Invalid state format', 'INVALID_FORMAT');
    }
    
    return { userId, token };
  } catch (error) {
    if (error instanceof OAuthError) throw error;
    throw new OAuthError('Failed to decode state', 'DECODE_ERROR');
  }
};