/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { FaGoogle, FaUser, FaBuilding, FaLock } from 'react-icons/fa';
import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';
import { toast } from 'react-toastify';
import 'react-phone-number-input/style.css';
import '../styles/CreateAccountForm.css';

const BLOCKED_DOMAINS = [
  'yahoo.com', 'hotmail.com', 'outlook.com', 'aol.com',
  'icloud.com', 'mail.com', 'protonmail.com', 'zoho.com'
];

const workRoles = [
  { value: '', label: 'Select your work role' },
  { value: 'compliance_officer', label: 'Compliance Officer' },
  { value: 'analyst', label: 'Analyst' },
  { value: 'audit_team_member', label: 'Audit Team Member' },
  { value: 'auditor', label: 'Auditor' },
  { value: 'compliance_analyst', label: 'Compliance Analyst' },
  { value: 'compliance_director', label: 'Compliance Director' },
  { value: 'quality_control_inspector', label: 'Quality Control Inspector' },
  { value: 'quality_manager', label: 'Quality Manager' },
  { value: 'vice_president_compliance', label: 'Vice President Compliance' },
  { value: 'compliance_manager', label: 'Compliance Manager' },
  { value: 'director', label: 'Director' },
  { value: 'management', label: 'Management' },
  { value: 'quality_engineer', label: 'Quality Engineer' },
  { value: 'risk_manager', label: 'Risk Manager' },
  { value: 'student', label: 'Student' },
  { value: 'department_owner', label: 'Department Owner' },
  { value: 'risk_team_member', label: 'Risk Team Member' },
  { value: 'security_operations', label: 'Security Operations' },
  { value: 'other', label: 'Other' }
];

const calculateStepProgress = (currentStep) => {
  return Math.min((currentStep / 4) * 100, 100);
};

const validateWorkEmail = (email) => {
  if (!email) return 'Work email is required';
  if (!email.includes('@')) return 'Please enter a valid email address';
  
  const domain = email.split('@')[1].toLowerCase();
  if (BLOCKED_DOMAINS.includes(domain)) {
    return 'Please use your work email address. Personal email domains are not accepted.';
  }
  
  return '';
};

const SignupPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { referralCode: urlRefCode } = useParams();

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    phone: '',
    company: '',
    industry: '',
    password: '',
    confirmPassword: '',
    workRole: '',
    otherWorkRole: '',
    country: '',
    referralCode: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getReferralCode = () => {
      if (urlRefCode) {
        return urlRefCode;
      }
      
      const urlParams = new URLSearchParams(location.search);
      const queryRefCode = urlParams.get('ref');
      if (queryRefCode) {
        return queryRefCode;
      }
      
      const pathParts = location.pathname.split('/');
      const lastPath = pathParts[pathParts.length - 1];
      if (lastPath && lastPath !== 'signup') {
        return lastPath;
      }
      
      return '';
    };

    const code = getReferralCode();
    if (code) {
      setFormData(prev => ({ ...prev, referralCode: code }));
    }
  }, [location, urlRefCode]);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const error = validateWorkEmail(newEmail);
    setEmailError(error);
    setIsEmailValid(!error);
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handlePhoneChange = (value) => {
    if (value) {
      try {
        const phoneNumber = parsePhoneNumber(value);
        if (phoneNumber) {
          setFormData(prev => ({
            ...prev,
            phone: value,
            country: phoneNumber.country || prev.country
          }));
        }
      } catch (error) {
        setFormData(prev => ({
          ...prev,
          phone: value
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        phone: '',
        country: ''
      }));
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    return phoneNumber && phoneNumber.length >= 10;
  };

  const getStepTitle = () => {
    switch (step) {
      case 1: return "Let's get started";
      case 2: return "Personal Information";
      case 3: return "Professional Details";
      case 4: return "Security Setup";
      default: return "";
    }
  };

  const getStepDescription = () => {
    switch (step) {
      case 1: return "Begin with your work email";
      case 2: return "Tell us about yourself";
      case 3: return "Share your professional background";
      case 4: return "Create a secure password";
      default: return "";
    }
  };

  const handleNextStep = async (event) => {
    event.preventDefault();
    let error = '';

    switch (step) {
      case 1:
        if (!isEmailValid) {
          error = 'Please enter a valid work email';
        }
        break;
      case 2:
        if (!formData.firstName || !formData.lastName) {
          error = 'First name and last name are required';
        } else if (!validatePhoneNumber(formData.phone)) {
          error = 'Please enter a valid phone number';
        }
        break;
      case 3:
        if (!formData.company || !formData.workRole) {
          error = 'Company and work role are required';
        }
        break;
      case 4:
        if (!formData.password || !formData.confirmPassword) {
          error = 'Both password fields are required';
        } else if (formData.password !== formData.confirmPassword) {
          error = 'Passwords must match';
        }
        break;
    }

    if (error) {
      setErrorMessage(error);
      toast.error(error, {
        position: "bottom-right",
        autoClose: 5000
      });
      return;
    }

    setErrorMessage('');
    setStep(step + 1);
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);
  
    try {
      // Prepare request data
      const signupData = {
          firstName: formData.firstName.trim(),
          middleName: (formData.middleName || '').trim(),
          lastName: formData.lastName.trim(),
          email: email.trim().toLowerCase(),
          phone: formData.phone,
          company: formData.company.trim(),
          industry: formData.industry.trim(),
          workRole: formData.workRole === 'other' ? formData.otherWorkRole.trim() : formData.workRole.trim(),
          country: formData.country || 'US',
          password: formData.password
      };
     
      const referralCodeParam = formData.referralCode ? `?ref=${encodeURIComponent(formData.referralCode.trim())}` : '';
      const signupResponse = await fetch(`https://app.spiralreports.com/api/users/signup${referralCodeParam}`, {
        method: 'POST',
        headers: {
          'accept': '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(signupData),
      });
  
      if (!signupResponse.ok) {
        const errorData = await signupResponse.json();
        throw new Error(errorData.message || 'Failed to create account');
      }
  
      const responseData = await signupResponse.json();
      
      localStorage.setItem('access_token', responseData.data.access_token);
      localStorage.setItem('refresh_token', responseData.data.refresh_token);
  
      if (responseData.data.user.isEmailVerified) {
        toast.success('Account created successfully! You can now log in.', {
          position: "bottom-right",
          autoClose: 3000
        });
        navigate('/login');
      } else {
        const confirmEmailResponse = await fetch('https://app.spiralreports.com/api/users/send-confirm-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${responseData.data.access_token}`
          }
        });
  
        if (!confirmEmailResponse.ok) {
          throw new Error('Failed to send confirmation email');
        }
  
        navigate('/email-confirmation', { 
          state: { 
            email: email 
          }
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "bottom-right",
        autoClose: 5000
      });
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const progress = calculateStepProgress(step);

  return (
    <div className="signup-form-container">
      <div className="progress-container">
        <div className="progress-info">
          <span>Step {step} of 4</span>
          <span>{Math.round(progress)}% Complete</span>
        </div>
        <div className="progress-bar">
          <div 
            className="progress-fill"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      {formData.referralCode && (
        <div className="text-sm text-gray-600 mb-4 text-center">
          Signing up with referral code: {formData.referralCode}
        </div>
      )}

      <div className="signup-header">
        <h1 className="signup-title">{getStepTitle()}</h1>
        <p className="signup-subtitle">{getStepDescription()}</p>
      </div>

      {errorMessage && (
        <div className="message error-message">{errorMessage}</div>
      )}

      {successMessage && (
        <div className="message success-message">{successMessage}</div>
      )}

      <form onSubmit={step === 4 ? handleSignup : handleNextStep}>
        {step === 1 && (
          <div className="form-field">
            <div className="relative">
              <Input
                id="email"
                type="email"
                placeholder="Work Email"
                value={email}
                onChange={handleEmailChange}
                className={`field-input ${emailError ? 'error' : ''}`}
              />
              <FaUser className="field-icon" />
            </div>
            {emailError && (
              <p className="error-message">{emailError}</p>
            )}

            <Button 
              variant="outline" 
              type="button"
              onClick={() => window.location.href = '/auth/google'}
              className="google-button"
            >
              <FaGoogle className="text-lg" />
              Continue with Google
            </Button>

            <div className="divider-container">
              <div className="divider-line" />
              <span className="divider-text">or</span>
              <div className="divider-line" />
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="form-field">
            <div className="grid grid-cols-2 gap-4">
              <Input
                id="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                className="field-input"
              />
              <Input
                id="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
                className="field-input"
              />
            </div>
            <Input
              id="middleName"
              placeholder="Middle Name (Optional)"
              value={formData.middleName}
              onChange={handleInputChange}
              className="field-input"
            />
            
            <div className="phone-input-container">
              <label className="field-label">Business Phone Number</label>
              <PhoneInput
                value={formData.phone}
                onChange={handlePhoneChange}
                placeholder="Enter phone number"
                className="phone-input"
                defaultCountry="US"
                international
                countryCallingCodeEditable={false}
              />
              <p className="text-xs text-gray-500">
                Please provide a business phone number where we can reach you.
              </p>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="form-field">
            <div className="relative">
              <Input
                id="company"
                placeholder="Company Name"
                value={formData.company}
                onChange={handleInputChange}
                className="field-input"
              />
              <FaBuilding className="field-icon" />
            </div>
            <Input
              id="industry"
              placeholder="Industry"
              value={formData.industry}
              onChange={handleInputChange}
              className="field-input"
            />
            <select
              id="workRole"
              value={formData.workRole}
              onChange={handleInputChange}
              className="field-input"
            >
              {workRoles.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </select>
            {formData.workRole === 'other' && (
              <Input 
                id="otherWorkRole" 
                placeholder="Please specify your work role" 
                value={formData.otherWorkRole} 
                onChange={handleInputChange}
                className="field-input"
              />
            )}
          </div>
        )}

        {step === 4 && (
          <div className="form-field">
            <PasswordCheckerWithConfirm 
              password={formData.password}
              setPassword={(password) => setFormData(prev => ({ ...prev, password }))}
              confirmPassword={formData.confirmPassword}
              setConfirmPassword={(confirmPassword) => setFormData(prev => ({ ...prev, confirmPassword }))}
              required={true}
            />
          </div>
        )}

        <div className="button-container">
          {step > 1 && (
            <button
              type="button"
              className="nav-button back-button"
              onClick={() => setStep(step - 1)}
            >
              Back
            </button>
          )}
          <button 
            type="submit"
            className="nav-button next-button"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="flex items-center justify-center gap-2">
                <svg className="loading-spinner h-5 w-5" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"/>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
                Processing...
              </span>
            ) : (
              step === 4 ? 'Create Account' : 'Continue'
            )}
          </button>
        </div>

        <div className="signin-link">
          Already have an account?{' '}
          <button
            type="button"
            onClick={() => navigate('/login')}
            className="text-red-600 hover:text-red-700"
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  );
};

const PasswordCheckerWithConfirm = ({ 
  password, 
  setPassword, 
  confirmPassword, 
  setConfirmPassword, 
  required 
}) => {
  const [validationResults, setValidationResults] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });
  const [passwordMatch, setPasswordMatch] = useState(false);

  const checkPasswordStrength = (password) => {
    setValidationResults({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordStrength(newPassword);
    setPasswordMatch(confirmPassword === newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmValue = e.target.value;
    setConfirmPassword(confirmValue);
    setPasswordMatch(confirmValue === password);
  };

  return (
    <div className="password-container">
      <div className="relative">
        <Input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          className="field-input"
          placeholder="Create password"
          required={required}
        />
        <FaLock className="field-icon" />
      </div>

      <div className="password-requirements">
        {Object.entries(validationResults).map(([key, valid]) => (
          <div key={key} className={`requirement-item ${valid ? 'text-green-600' : 'text-gray-500'}`}>
            <div className={`requirement-indicator ${valid ? 'requirement-valid' : 'requirement-invalid'}`}>
              {valid ? '✓' : '·'}
            </div>
            <span className="text-sm">
              {key === 'length' && 'At least 8 characters'}
              {key === 'uppercase' && 'Uppercase letter'}
              {key === 'lowercase' && 'Lowercase letter'}
              {key === 'digit' && 'Number'}
              {key === 'specialChar' && 'Special character'}
            </span>
          </div>
        ))}
      </div>

      <div className="form-field">
        <div className="relative">
          <Input
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className={`field-input ${required && !confirmPassword ? 'error' : ''}`}
            placeholder="Confirm password *"
            required={required}
          />
          <FaLock className="field-icon" />
        </div>
        {confirmPassword && (
          <p className={passwordMatch ? 'success-message' : 'error-message'}>
            {passwordMatch ? '✓ Passwords match' : '× Passwords do not match'}
          </p>
        )}
      </div>
    </div>
  );
};

export default SignupPage;