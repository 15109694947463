// src/pages/Dashboard.jsx
import React, { useEffect, useState } from 'react';
import { 
  BarChart3, ClipboardCheck, Clock, PlayCircle, TrendingUp, Plus, Star, 
  ArrowUpRight
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useAuth } from '../hooks/AuthContext';
import useReport from '../hooks/useReport';
import useUserProfile from '../hooks/useUserProfile';
import PageLoader from '../components/ui/PageLoader';

const DashboardStats = ({ icon: Icon, label, value, trend, description }) => (
  <div className="bg-white rounded-lg p-6 shadow-sm">
    <div className="flex flex-col gap-4">
      <div className="p-2.5 bg-red-50 rounded-lg w-fit">
        <Icon className="w-5 h-5 text-red-500" />
      </div>
      <div>
        <div className="text-sm text-gray-600 mb-1">{label}</div>
        <div className="text-2xl font-bold text-gray-900">{value}</div>
        {description && (
          <div className="text-sm text-gray-500 mt-1">{description}</div>
        )}
      </div>
    </div>
    {trend && (
      <div className="mt-2 flex items-center gap-1 text-green-600 text-sm">
        <ArrowUpRight className="w-4 h-4" />
        {trend}
      </div>
    )}
  </div>
);

const AssessmentRow = ({ id, title, department, date, score, icon: Icon = Clock, onViewReport }) => {
  const formattedDate = date ? new Date(date).toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }) : null;

  return (
    <div 
      className="flex items-center py-4 hover:bg-gray-50 px-4 -mx-4 cursor-pointer transition-colors"
      onClick={() => onViewReport(id)}
    >
      <div className="p-2 bg-gray-100 rounded-lg mr-4">
        <Icon className="w-4 h-4 text-gray-500" />
      </div>
      <div className="flex-1 min-w-0">
        <div className="font-medium text-gray-900 truncate">{title}</div>
        <div className="text-sm text-gray-500 truncate">
          {department} {formattedDate && `• ${formattedDate}`}
        </div>
      </div>
      <div className="text-base font-medium text-gray-900 ml-4">{score}</div>
    </div>
  );
};

const ErrorAlert = ({ title, description }) => (
  <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
    <h3 className="text-sm font-medium text-red-800">{title}</h3>
    <p className="mt-1 text-sm text-red-700">{description}</p>
  </div>
);

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authData } = useAuth();
  const { fetchReport } = useReport();
  const [reportLoading, setReportLoading] = useState(false);
  
  const { 
    userProfile, 
    loading: profileLoading, 
    error: profileError 
  } = useUserProfile();
  
  const access_token = authData?.access_token || authData?.accessToken;
  const navigate = useNavigate();

  const handleViewReport = async (assessmentId) => {
    if (reportLoading) return;

    setReportLoading(true);
    try {
      const reportData = await fetchReport(assessmentId);
      if (reportData) {
        navigate(`/report/${assessmentId}`, { state: { reportData } });
      }
    } catch (error) {
      console.error('Error fetching report:', error);
    } finally {
      setReportLoading(false);
    }
  };

  const handleViewAllAssessments = () => {
    navigate('/assessments');
  };

  const handleStartAssessment = () => {
    navigate('/assessment-flow');
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (!access_token) {
        setError('Authentication required');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://app.spiralreports.com/api/dashboard', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch dashboard data');
        }

        const data = await response.json();
        setDashboardData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [access_token]);

  if (loading || reportLoading || profileLoading) {
    return <PageLoader />;
  }

  const { 
    recent_assessments = [], 
    popular_assessments = [], 
    total_assessments = 0, 
    assessments_this_month = 0, 
    average_maturity_score = 0 
  } = dashboardData?.data || {};

  return (
    <div className="min-h-screen bg-gray-50">
      <main className="pt-20 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {(error || profileError) && (
            <ErrorAlert 
              title="Error" 
              description={error || profileError} 
            />
          )}

          {userProfile && (
            <div className="py-4">
              
              {userProfile.role && (
                <p className="text-gray-500 mt-1">{userProfile.role}</p>
              )}
            </div>
          )}
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:flex gap-4 lg:gap-6 mb-6 sm:mb-8">
            <div className="lg:flex-1">
              <DashboardStats
                icon={ClipboardCheck}
                label="Total Assessments"
                value={total_assessments}
                description="Total number of assessments completed"
              />
            </div>
            <div className="lg:flex-1">
              <DashboardStats
                icon={BarChart3}
                label="Assessments This Month"
                value={assessments_this_month}
                trend={`${assessments_this_month > 0 ? '+' : ''}${assessments_this_month}% vs last month`}
              />
            </div>
            <div className="lg:flex-1">
              <DashboardStats
                icon={TrendingUp}
                label="Average Maturity Score"
                value={`${average_maturity_score}%`}
                description="Overall maturity across all assessments"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-8">
            <div className="lg:col-span-2 space-y-6">
              <div className="bg-white rounded-lg p-6 shadow-sm">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">Recent Assessments</h2>
                  <button 
                    onClick={handleViewAllAssessments}
                    className="text-sm text-gray-600 hover:text-red-500 px-3 py-2 rounded-md transition-colors"
                  >
                    View All
                  </button>
                </div>
                {recent_assessments.length > 0 ? (
                  <div className="divide-y divide-gray-100">
                    {recent_assessments.map((assessment) => (
                      <AssessmentRow
                        key={assessment.id}
                        {...assessment}
                        onViewReport={handleViewReport}
                      />
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 text-center py-8">
                    No recent assessments found
                  </p>
                )}
              </div>

              <div className="bg-white rounded-lg p-6 shadow-sm">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold text-gray-900">Popular Assessments</h2>
                  <button 
                    className="text-sm text-gray-600 hover:text-red-500 px-3 py-2 rounded-md transition-colors"
                  >
                    View All
                  </button>
                </div>
                {popular_assessments.length > 0 ? (
                  <div className="divide-y divide-gray-100">
                    {popular_assessments.map((assessment) => (
                      <AssessmentRow
                        key={assessment.id}
                        {...assessment}
                        icon={Star}
                        onViewReport={handleViewReport}
                      />
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 text-center py-8">
                    No popular assessments found
                  </p>
                )}
              </div>
            </div>

            <div className="space-y-6">
              <div className="bg-white rounded-lg p-6 shadow-sm">
                <div className="text-center">
                  <div className="w-12 h-12 bg-red-50 rounded-full flex items-center justify-center mx-auto mb-4">
                    <PlayCircle className="w-6 h-6 text-red-500" />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    Start New Assessment
                  </h3>
                  <p className="text-sm text-gray-500 mb-6">
                    Begin a new assessment to evaluate your team's maturity.
                  </p>
                  <button
                    onClick={handleStartAssessment}
                    className="w-full flex items-center justify-center gap-2 text-sm font-medium text-white bg-red-500 hover:bg-red-600 rounded-lg py-2.5 px-4 transition-colors"
                  >
                    <Plus className="w-4 h-4" />
                    Start Assessment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;