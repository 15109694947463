import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { useAuth } from '../hooks/AuthContext';
import { decodeState, OAuthError } from '../utils/oauth-utils';

const OAuthReturn = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const handleOAuthReturn = async () => {
      try {
        // Get URL parameters
        const params = new URLSearchParams(location.search);
        const state = params.get('state');
        const oauthError = params.get('error');

        // Debug logging in development
        if (process.env.NODE_ENV === 'development') {
          console.log('OAuth Return Debug:', {
            receivedState: state,
            fullUrl: window.location.href
          });
        }

        // Handle OAuth error response
        if (oauthError) {
          throw new OAuthError(`OAuth provider error: ${oauthError}`, 'PROVIDER_ERROR');
        }

        // Validate state parameter
        if (!state) {
          throw new OAuthError('Missing state parameter', 'MISSING_STATE');
        }

        // Decode the received state
        let decodedState;
        try {
          decodedState = decodeState(state);
          console.log('Successfully decoded state:', decodedState);
        } catch (decodeError) {
          console.error('State decode error:', decodeError);
          throw new OAuthError('Failed to decode state', 'DECODE_ERROR');
        }

        // Validate decoded state contents
        const { userId, token } = decodedState;
        if (!userId || !token) {
          throw new OAuthError('Invalid state content', 'INVALID_STATE_CONTENT');
        }

        // Call refresh token API
        try {
          const response = await fetch('https://app.spiralreports.com/api/auth/user/refresh', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: userId,
              refreshToken: token,
            }),
          });

          if (!response.ok) {
            throw new Error(`Refresh token failed with status: ${response.status}`);
          }

          const { data } = await response.json();
          console.log('Refresh token response:', data);

          if (!data?.access_token || !data?.refresh_token || !data?.user) {
            throw new Error('Invalid response format');
          }

          // Store complete auth data
          const authData = {
            userId: data.user._id,
            accessToken: data.access_token,
            refreshToken: data.refresh_token,
            user: data.user  // Store complete user object
          };

          // Save to AuthContext
          login(authData);
          console.log('Stored auth data:', authData);

          // Clean up stored state
          sessionStorage.removeItem('googleOAuthState');

          // Redirect to dashboard
          setIsProcessing(false);
          navigate('/dashboard');

        } catch (refreshError) {
          console.error('Refresh token error:', refreshError);
          throw new OAuthError('Failed to refresh token', 'REFRESH_ERROR');
        }

      } catch (error) {
        console.error('Authentication error:', error);
        
        let errorMessage = 'Authentication failed. Please try again.';
        if (error instanceof OAuthError) {
          switch (error.code) {
            case 'PROVIDER_ERROR':
              errorMessage = 'Google authentication failed. Please try again.';
              break;
            case 'MISSING_STATE':
              errorMessage = 'Invalid authentication response. Please try again.';
              break;
            case 'DECODE_ERROR':
              errorMessage = 'Invalid authentication data. Please try again.';
              break;
            case 'INVALID_STATE_CONTENT':
              errorMessage = 'Invalid authentication response data. Please try again.';
              break;
            case 'REFRESH_ERROR':
              errorMessage = 'Failed to initialize session. Please try again.';
              break;
            default:
              errorMessage = 'Authentication failed. Please try again.';
          }
        }

        setError(errorMessage);
        setIsProcessing(false);

        // Redirect to login only on actual authentication errors
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    };

    handleOAuthReturn();
  }, [navigate, location, login]);

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 max-w-md">
          <h2 className="text-red-700 font-semibold mb-2">Authentication Error</h2>
          <p className="text-red-600 mb-2">{error}</p>
          <p className="text-sm text-red-500">Redirecting to login page...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="flex flex-col items-center gap-4">
        <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
        <p className="text-gray-700">
          {isProcessing ? 'Completing sign in...' : 'Redirecting to dashboard...'}
        </p>
      </div>
    </div>
  );
};

export default OAuthReturn;